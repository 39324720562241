export const CustomPatternValidation = {
  firstName: '^[A-Za-z]{1}[A-Za-z .]{0,}$',
  middleName: '^[A-Za-z]{1}[A-Za-z ]{0,}$',
  lastName: '^[A-Za-z]{1}[A-Za-z]{0,}$',
  fullName: '^[A-Za-z]{1}[A-Za-z .]{0,}$',
  phoneNumber: '^[1-9]{1}[0-9]{2}[0-9]{3}[0-9]{4}$',
  alternateNumber: '^[1-9]{1}[0-9]{2}[0-9]{3}[0-9]{4}$',
  localContactName: '^[A-Za-z]{1}[A-Za-z .]{1,192}$',
  localContactNumber: '^[1-9]{1}[0-9]{2}[0-9]{3}[0-9]{4}$',
  landlineNumber:
    '^((?=0)([0-9]{1}((?=0)([0-9]{1}[1-9]{1})|([1-9]{1}[0-9]{1})))|([1-9]{1}[0-9]{2}))((?=-| )([-| ]{1}[1-9]{1}[0-9]{2}[0-9]{4}$)|([1-9]{1}[0-9]{2}[0-9]{4}$))',
  address: '^[A-Za-z0-9#?!@$%^&*]{1}[A-Za-z0-9#?!@$%^&* .,/:-]{2,}$',
  zipcode: '^[1-9]{1}[0-9]{3,7}$',
  // dosage: '^[1-9]{1}[0-9 .]{0,8}$',
  dosage: '^[0-9]{1}[0-9]{0,8}[.]{0,1}[0-9]{0,2}$',
  cycleweeks: '^[1-9]{1}[0-9]{0,8}$',
  fusionCycle: '^[0-9]{1,9}$',
  paidInfusion: '^[0-9]{1,9}$',
  freeInfusion: '^[0-9]{1,9}$',
  theraphyDuration: '^[0-9]{1,9}$',
  IdNumber: '^[A-Z0-9]{1,20}$',
  nameMaxLength: 100,
  seperatedNameMaxLength: 50,
  email: '^[-a-zA-Z0-9_.*$]{2,64}[@]{1}[a-zA-Z0-9]{2,20}[.]{1}[a-zA-Z.]{2,240}$',
  passwordFormat: '', //^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,72}$
  emailMaxLength: 100,
  commentMaxLength: 500,
  descriptionMaxLength: 1000,
  meetingLinkMaxLength: 500,
  couponMaxLength: 100,
  age: '^[1-9]{1}[0-9]{0,3}$',
  frequencyDays: '^[1-9]{1}[0-9]{0,8}$',
  numbers: '^[1-9]{1}[0-9]{0,8}$',
  addressMaxLength: 250,
  addressValidation: /^[^-"\s][a-zA-Z0-9./#@&%,-\s-]*$/,
  country: '^[A-Za-z .]{1,}$',
  state: '^[A-Za-z .]{1,}$',
  city: '^[A-Za-z .]{1,}$',
  url: /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/,
  sequenceCode: '^[a-zA-Z0-9]{0}[a-zA-Z0-9-|()\\[\\]]{0,20}$',
  sequenceMaxLength: 20,
  sequenceMinLength: 10,
  groupNameMaxLength: 20,
  groupNameMinLength: 10,
  fundAmount: /^[0-9]+$/,
  validIdProofMaxLength: 30,
  validIdProofPattern: '^[a-zA-Z0-9]{1,30}',
  infusionGap: '^[0-9]{1,2}',
  nonZeroValue: /^[1-9]+$/,
  boxCount: /^[1-9]\d?$/,
  onlyNumber: /^\d+$/
};
