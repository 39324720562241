<div class="create-password-page-wrapper login-bg-wrapper login-outer-wrapper login-page-wrapper">
  <div class="w-100 p-0 h-100 tbt-bg-container position-relative">
    <div class="row align-items-center h-100 g-0">
      <div class="col-lg-6 d-none d-lg-block d-sm-none bg-color-left">
        <div class="login-bg-inner-wrapper d-flex align-items-center justify-content-center">
          <img src="passwordCreationCMSContent?.images?.pcsLogo" class="img-fluid login-logo" alt="PCS Program"
            width="300" height="350" />
        </div>
      </div>

      <div class="col-lg-6 col-sm-12 login-bg"
        style="background-image: url({{ passwordCreationCMSContent?.images?.PCSLoginbg }})">
        <div class="login-wrapper h-100">
          <div class="container-fluid px-3 h-100">
            <div class="row gx-0 h-100">
              <div class="col-12 login-inner-wrapper-col align-self-center">
                <div class="login-inner-wrapper create-password">
                  <div class="card border-0 tbt-rounded-xl pcs-card--shadow">
                    <div class="tbt-card-body--password-creation card-body">
                      <div class="heading-wrapper">
                        <h3 class="heading">{{ passwordCreationCMSContent?.pagetitles?.createPassword }}</h3>
                        <p>{{ passwordCreationCMSContent?.pageHeadings?.createNewPassword }}</p>
                      </div>
                      <form class="login-form" [formGroup]="userPasswordForm">
                        <div class="form-group prepend-group required-asterisk">
                          <label class="custom-label" for="password">{{ passwordCreationCMSContent?.labels?.newPassword
                            }}</label>
                          <div class="input-group new-password-input-group tbt-input-group">
                            <input [type]="passwordVisiblity ? 'text' : 'password'" class="form-control"
                              formControlName="password" list="datalistOptions"
                              placeholder="{{ passwordCreationCMSContent?.placeholders?.newPassword }}"
                              aria-label="Search" aria-describedby="basic-addon2" (keyup)="passwordLen()" />
                            <span class="input-group-text" id="basic-addon2">
                              <img [src]="
                                  passwordVisiblity
                                    ? 'passwordCreationCMSContent?.images?.password_visible'
                                    : 'passwordCreationCMSContent?.images?.password_not_visible'
                                " alt="" (click)="toggleFieldTextTypePass()" class="img-fluid" />
                            </span>
                          </div>
                          <p [ngClass]="
                              passwordlen && userPasswordForm.value.password !== ''
                                ? 'password-success-message'
                                : 'password-error-message'
                            ">
                            {{ passwordCreationCMSContent?.validation?.newPassword }}
                          </p>
                        </div>
                        <div class="form-group prepend-group required-asterisk">
                          <label class="custom-label" for="confirm_password">{{
                            passwordCreationCMSContent?.labels?.cnfirmNewPassword
                            }}</label>
                          <div class="input-group new-password-input-group tbt-input-group">
                            <input [type]="confirmPasswordVisibility ? 'text' : 'password'" class="form-control"
                              list="datalistOptions" formControlName="confirm_password"
                              placeholder="{{ passwordCreationCMSContent?.placeholders?.againNewPassword }}"
                              aria-label="Search" aria-describedby="basic-addon2" (keyup)="keyFunc()" />
                            <span class="input-group-text" id="basic-addon2">
                              <img [src]="
                                  confirmPasswordVisibility
                                    ? 'passwordCreationCMSContent?.images?.password_visible'
                                    : 'passwordCreationCMSContent?.images?.password_not_visible'
                                " alt="" (click)="toggleFieldTextTypeConfirm()" class="img-fluid" />
                            </span>
                          </div>
                          <p [ngClass]="
                              passwordMatch && userPasswordForm.value.confirm_password !== ''
                                ? 'password-success-message'
                                : 'password-error-message'
                            ">
                            {{
                            passwordMatch && userPasswordForm.value.confirm_password !== ''
                            ? 'password match '
                            : 'Passwords must match'
                            }}
                          </p>
                        </div>
                        <div class="action-checkboxes">
                          <div class="form-check create-password-terms-section">
                            <label class="container-checkbox custom-checkbox-wrapper">
                              <input type="checkbox" class="form-check-input" formControlName="acceptTerms"
                                id="exampleCheck1" />
                              <span class="checkmark"></span>
                              {{ passwordCreationCMSContent?.labels?.agree  }}
                              <a class="tbt-login-agree text-decoration-none" href="javascript:void(0);"
                                (click)="termsAndConditionsDialog(termsConditionsModal)">{{ passwordCreationCMSContent?.buttons?.termsConditions }}</a>
                            </label>
                            <div *ngIf="
                                (userPasswordForm.controls['acceptTerms'].invalid &&
                                  userPasswordForm.controls['acceptTerms'].touched) ||
                                userPasswordForm.controls['acceptTerms'].dirty
                              ">
                              <span class="form-group-error-message"
                                *ngIf="userPasswordForm.controls?.['acceptTerms']?.hasError('required')">
                                {{ passwordCreationCMSContent?.validation?.acceptTermsConditions }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="submit" class="btn tbt-primary-btn btn-login"
                            (click)="createNewPassword()" [disabled]="!enableButton()" [ngClass]="{
                              'invalid-btn': userPasswordForm.invalid
                            }">
                            {{ passwordCreationCMSContent?.buttons?.createnewpassword }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #termsConditionsModal let-modal>
  <div class="modal-wrapper">
    <div class="modal-header">
      <div class="flex-grow-1">
        <h4 class="terms-title text-center">
          {{ termsAndConditionsData?.post_title || publicTermsAndConditionsData?.post_title }}
        </h4>
      </div>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <div class="container scroll terms-condition-header">
        <div [innerHTML]="termsAndConditionsData?.post_content || publicTermsAndConditionsData?.post_content"></div>
      </div>
    </div>
  </div>
</ng-template>