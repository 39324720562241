import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomPatternValidation } from 'src/app/shared/constants/validations';
import { AlertService } from 'src/app/core/services/alert.service';
import { CmsService } from 'src/app/core/services/cms.service';
import publicTermsAndConditionsContent from '../../../../json/public/terms&conditions.json';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PcsUserManagementService, ResetPassword } from 'src/app/core/services/pcs-user-management.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
})
export class ForgotPasswordComponent implements OnInit {
  subscription: Subscription = new Subscription();
  httpClient: any;
  forgetPasswordForm!: FormGroup;
  rolesOptions: any = [];
  @BlockUI()
  blockUI!: NgBlockUI;
  alertSubscribe!: Subscription;
  forgotPasswordCMSData: any;
  termsAndConditionsData: any;
  publicTermsAndConditionsData: any;
  loginCMSData: any;

  constructor(
    private formBuilder: FormBuilder,
    private userManagementService: PcsUserManagementService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private cmsService: CmsService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.cmsService.getCMSPreContent().subscribe((res) => {
      this.forgotPasswordCMSData = res;
      this.loginCMSData = res;
    });
    this.getTermsConditions();

    this.forgetPasswordForm = this.formBuilder.group({
      Email: [
        null,
        [
          Validators.required,
          Validators.pattern(CustomPatternValidation.email),
          Validators.maxLength(CustomPatternValidation.emailMaxLength),
        ],
      ],
      termsCondition: [false, Validators.requiredTrue],
    });
  }

  getTermsConditions() {
    const cmsTenantId = this.storageService.getData('cmsTenantID');
    const langCode = this.storageService.getData('langCode');
    this.cmsService.getTermsAndConditions(cmsTenantId, langCode, 'terms-and-conditions').subscribe(
      (res: any) => {
        this.blockUI.stop();
        this.termsAndConditionsData = res;
      },
      (err: any) => {
        this.blockUI.stop();
        this.publicTermsAndConditionsData = publicTermsAndConditionsContent;
      }
    );
  }
  termsAndConditionsDialog(termsConditionsModal: any) {
    this.modalService.open(termsConditionsModal, {
      size: 'lg',
      centered: true,
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  errorFunction(err: HttpErrorResponse): void {
    this.blockUI.stop();
    this.alertService.showErrorInformation(err);
  }

  resetPassword(): void {
    this.blockUI.start('Loading...');
    const forgetPasswordData: ResetPassword = {
      emailId: this.forgetPasswordForm.value.Email,
    };
    this.userManagementService.forgetPassword(forgetPasswordData).subscribe(
      (res: any) => {
        this.blockUI.stop();
        this.forgetPasswordForm.reset();
        if (res.error === false) {
          this.alertSubscribe = this.alertService
            .showTBTSuccess({
              title: 'Success!',
              text: `${res.data}`,
            })
            .subscribe((data) => {
              this.alertSubscribe.unsubscribe();
            });
        }

        if (res.error === true) {
          this.alertSubscribe = this.alertService
            .showWarningError({
              title: 'Error',
              text: `${res.data.messages[0].toString()}`,
            })
            .subscribe((data) => {
              this.alertSubscribe.unsubscribe();
            });
        }
      },
      (err: any) => {
        this.errorFunction(err);
      }
    );
  }
}
