<div class="forgot-password-page-wrapper login-bg-wrapper login-outer-wrapper w-100">
  <div class="container-fluid p-0 h-100 tbt-bg-container position-relative">
    <div class="row h-100 g-0 align-items-center">
      <div id="tbtlogin" class="col-lg-6 d-none d-lg-block d-sm-none bg-color-left">
        <div class="login-bg-inner-wrapper d-flex align-items-center justify-content-center">
          <img [src]="loginCMSData?.images?.pcsLogo" class="img-fluid login-logo" alt="PCS Program" width="300"
            height="350" />
        </div>
      </div>

      <div class="col-lg-6">
        <div class="login-wrapper h-100">
          <div class="container-fluid h-100">
            <div class="row h-100">
              <div class="col-12 align-self-center">
                <div class="login-inner-wrapper">
                  <div class="back-btn-wrapper">
                    <a class="text-decoration-none" routerLink="/"><img [src]="loginCMSData?.images?.arrow_left"
                        alt=""><span>Back</span></a>
                  </div>

                  <div class="card border-0 tbt-rounded-xl tbt-card--shadow">
                    <div class="tbt-card-body--forgot-password card-body">
                      <div class="heading-wrapper">
                        <h3 class="heading">
                          {{forgotPasswordCMSData?.titles?.forgotPassword_title }}
                        </h3>
                        <p>
                          {{forgotPasswordCMSData?.subtitles?.forgotPassword_subtitle }}
                        </p>
                      </div>
                      <form class="login-form" [formGroup]="forgetPasswordForm">
                        <div class="form-group required-asterisk">
                          <label class="mb-0 custom-label" for="email">
                            {{forgotPasswordCMSData?.labels?.email }}
                          </label>
                          <input id="email" type="email" class="form-control" aria-describedby="emailHelp"
                            formControlName="Email" placeholder="{{forgotPasswordCMSData?.placeholders?.offcialEmail }}"
                            [ngClass]="{
                              'error-field':
                                forgetPasswordForm.controls['Email'].invalid &&
                                (forgetPasswordForm.controls['Email'].touched ||
                                  forgetPasswordForm.controls['Email'].dirty)
                            }" />
                          <div class="form-group-error-message" *ngIf="
                              forgetPasswordForm.controls['Email'].invalid &&
                              (forgetPasswordForm.controls['Email'].touched ||
                                forgetPasswordForm.controls['Email'].dirty)
                            ">
                            <span *ngIf="forgetPasswordForm.controls?.['Email']?.hasError('required')">
                              {{forgotPasswordCMSData?.validation?.email }}</span>
                            <span *ngIf="forgetPasswordForm.controls['Email'].errors?.['pattern']">
                              {{forgotPasswordCMSData?.validation?.validEmail }}
                            </span>
                            <span *ngIf="
                                !forgetPasswordForm.controls['Email'].errors?.['pattern'] &&
                                forgetPasswordForm.controls['Email'].errors?.['maxlength']
                              ">
                              {{forgotPasswordCMSData?.validation?.exceedMaxLength }}
                            </span>
                          </div>
                        </div>
                        <div class="action-checkboxes">
                          <div class="form-check">
                            <label class="container-checkbox custom-checkbox-wrapper">
                              {{ forgotPasswordCMSData?.labels?.accept }}
                              <a class="tbt-login-agree text-decoration-none"
                                (click)="termsAndConditionsDialog(termsConditionsModal)">
                                {{ forgotPasswordCMSData?.buttons?.termsConditions }}
                              </a>
                              <input type="checkbox" class="form-check-input forgot-checkbox" id="exampleCheck1"
                                formControlName="termsCondition" [ngClass]="{
                                  'error-field-checkbox':
                                    forgetPasswordForm.controls['termsCondition'].invalid &&
                                    (forgetPasswordForm.controls['termsCondition'].touched ||
                                      forgetPasswordForm.controls['termsCondition'].dirty)
                                }" />
                              <span class="checkmark"></span>
                            </label>
                            <div class="form-group-error-message" *ngIf="
                                forgetPasswordForm.controls['termsCondition'].invalid &&
                                (forgetPasswordForm.controls['termsCondition'].touched ||
                                  forgetPasswordForm.controls['termsCondition'].dirty)
                              ">
                              {{ forgotPasswordCMSData?.validation?.acceptTermsConditions }}
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                          <button type="submit" (click)="resetPassword()"
                            class="btn btn-login reset-btn tbt-primary-btn" [disabled]="forgetPasswordForm.invalid"
                            [ngClass]="{
                              'invalid-btn': forgetPasswordForm.invalid
                            }">
                            {{forgotPasswordCMSData?.buttons?.resetPassword }}
                          </button>
                        </div>
                        <div class="action-checkboxes forgot-password-login-link">
                          <div class="form-check already-register">
                            <label class="custom-label" class="form-check-label" for="exampleCheck1">{{
                              forgotPasswordCMSData?.labels?.alreadyRegister }}
                              <a routerLink="/login" class="text-decoration-none">{{
                                forgotPasswordCMSData?.buttons?.loginHere }}
                              </a>
                            </label>
                          </div>
                          <div class="forgot-passwd-wrapper">
                            <label class="custom-label" class="form-check-label" for="exampleCheck1"
                              >{{ forgotPasswordCMSData?.labels?.notRegistered  }}
                              <a class="text-decoration-none" routerLink="/sign-up">
                                {{forgotPasswordCMSData?.buttons?.registerHere }}
                              </a>
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #termsConditionsModal let-modal>
  <div class="modal-wrapper">
    <div class="modal-header">
      <div class="flex-grow-1">
        <h4 class="terms-title text-center">{{ termsAndConditionsData?.post_title ||
          publicTermsAndConditionsData?.post_title}}</h4>
      </div>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <div class="container scroll terms-condition-header">
        <div [innerHTML]="termsAndConditionsData?.post_content || publicTermsAndConditionsData?.post_content"></div>
      </div>
    </div>
  </div>
</ng-template>