import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { CustomPatternValidation } from 'src/app/shared/constants/validations';
import { AlertService } from 'src/app/core/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from 'src/app/core/services/cms.service';
import { CreatePassword, PcsUserManagementService } from 'src/app/core/services/pcs-user-management.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-password-creation',
  templateUrl: './password-creation.component.html',
  styles: [],
})
export class PasswordCreationComponent implements OnInit {
  passwordVisiblity = false;
  confirmPasswordVisibility = false;
  userPasswordForm!: FormGroup;
  passwordMatch = false;
  @BlockUI()
  blockUI!: NgBlockUI;
  alertSubscribe!: Subscription;
  passwordlen = false;
  email: any;
  termsAndConditionsData: any;
  publicTermsAndConditionsData: any;
  passwordCreationCMSContent: any;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private userManagementService: PcsUserManagementService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router,
    private cmsService: CmsService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.cmsService.getCMSContent().subscribe((res: any)=> {
      this.passwordCreationCMSContent = res;
    });
    this.route.paramMap.subscribe((params) => {
      this.email = window.atob(params?.get('email') || '');
    });
    this.userPasswordForm = this.formBuilder.group({
      password: [null, [Validators.required]],
      confirm_password: [null, [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue],
    });
    this.getTermsConditions();
  }

  getTermsConditions() {
    const cmsTenantId = this.storageService.getData('cmsTenantID');
    const langCode = this.storageService.getData('langCode');
    this.cmsService.getTermsAndConditions(cmsTenantId, langCode, 'terms-and-conditions').subscribe(
      (res: any) => {
        this.blockUI.stop();
        this.termsAndConditionsData = res;
      },
      (err: any) => {
        this.blockUI.stop();
        // this.publicTermsAndConditionsData = publicTermsAndConditionsContent;
      }
    );
  }

  termsAndConditionsDialog(termsConditionsModal: any) {
    this.modalService.open(termsConditionsModal, {
      size: 'lg',
      centered: true,
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  keyFunc(): void {
    const re = new RegExp(CustomPatternValidation.passwordFormat);
    re.test(this.userPasswordForm.value.confirm_password) &&
    this.userPasswordForm.value.password === this.userPasswordForm.value.confirm_password
      ? (this.passwordMatch = true)
      : (this.passwordMatch = false);
  }

  passwordLen(): void {
    const re = new RegExp(CustomPatternValidation.passwordFormat);
    re.test(this.userPasswordForm.value.password) ? (this.passwordlen = true) : (this.passwordlen = false);
    this.userPasswordForm.value.password === this.userPasswordForm.value.confirm_password
      ? (this.passwordMatch = true)
      : (this.passwordMatch = false);
  }

  enableButton(): boolean {
    return this.userPasswordForm.valid && this.passwordlen && this.passwordMatch;
  }

  toggleFieldTextTypePass(): void {
    this.passwordVisiblity = !this.passwordVisiblity;
  }

  toggleFieldTextTypeConfirm(): void {
    this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
  }

  errorFunction(err: HttpErrorResponse): void {
    this.blockUI.stop();
    this.alertService.showErrorInformation(err);
  }

  createNewPassword(): void {
    this.blockUI.start('Loading...');
    const CreatenewPassword: CreatePassword = {
      password: this.userPasswordForm.value.password,
      userEmail: this.email,
    };
    this.userManagementService.createPassword(CreatenewPassword).subscribe(
      (res) => {
        this.blockUI.stop();
        if (res.error === false) {
          this.alertSubscribe = this.alertService
            .showTBTSuccess({
              title: 'Success!',
              text: `${res.data}`,
            })
            .subscribe((data) => {
              if (data) {
                this.userPasswordForm.reset();
                this.passwordVisiblity = false;
                this.confirmPasswordVisibility = false;
                this.passwordlen = false;
                this.passwordMatch = false;
              }
              this.alertSubscribe.unsubscribe();
            });
        }

        if (res.error === true) {
          this.alertSubscribe = this.alertService
            .showWarningError({
              title: 'Error',
              text: `${res.data.messages[0].toString()}`,
            })
            .subscribe((data) => {
              this.alertSubscribe.unsubscribe();
            });
        }
      },
      (err) => {
        this.errorFunction(err);
      }
    );
  }
}
