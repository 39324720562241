import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { HttpWrapperService } from 'src/app/core/services/http-wrapper.service';
import { CommentsRolesEnum, ExceptionsIdsEnum } from 'src/app/core/enums/app-core.enum';

@Injectable({
  providedIn: 'root',
})
export class PcsEnrollmentService {
  private subjectHcpForm = new Subject<any>();
  private subjectStatesList = new Subject<any>();

  constructor(public httpWrapperService: HttpWrapperService) {}
  // ***START SERVICES METHOD'S HERE ***

  public getRoles(): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/public/web/roles', false);
  }

  addPatient(patient: FormData) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/pcs/enrollment/save-patient', patient, true);
  }

  ageAutoCal(selectedDate: any) {
    const today = new Date();
    const birthDate = selectedDate;
    let age = today.getFullYear() - birthDate.year;
    const m = today.getMonth() + 1 - birthDate.month;
    const d = today.getDate() - birthDate.day;
    if (m < 0 || (m === 0 && today.getDate() < birthDate.day)) {
      if (d < 0) {
        age--;
      }
    }
    return age;
  }

  addNewLeadOthersSave(otherDetails: any): Observable<any> {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/savePatientDetails',
      otherDetails,
      true
    );
  }

  savePatientDocument(document: FormData): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/pcs/document/mobile/uploadDoc', document, true);
  }

  getAllEnroll() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/enrollment/getAllEnrollmentDetails/', false);
  }

  getRelationshipTypes() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/enrollment/relationshipType', false);
  }
  getTcPatinetDetails(pcsId: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/getPatientDetails/' + pcsId,
      false
    );
  }
  getPatientDetails(pcsId: string) {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/enrollment/patientDetails?pcsId=' + pcsId, false);
  }
  getStates(selectedCountry: string | number) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/pcs/enrollment/states?countryId=' + selectedCountry,
      false
    );
  }
  getCitys(selectedState: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/pcs/enrollment/cities?stateId=' + selectedState,
      false
    );
  }
  getAllPhysician() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/hcpDetails', false);
  }

  getStateByHopsital(selectedHospital: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/pcs/tcPatientEnrollment/stateByHospitalId?hospitalId=' + selectedHospital,
      false
    );
  }

  getAllHcpState() {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/pcs/tcPatientEnrollment/masterdata/getAllStates',
      false
    );
  }

  getAllPaidFree() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/vialServices', false);
  }

  getAllFundingData() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/sourceOtherDetails', false);
  }

  getHospitalByState(stateName: any) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/pcs/tcPatientEnrollment/hospitalsByStateName?stateName=${stateName}`,
      false
    );
  }

  getAllPatientStatusData() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/flagsNumbersStatus', false);
  }

  checkPhoneNumberValidation(data: any) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/getPcsByPhoneNumber?phoneNumber=' + data,
      false
    );
  }

  checkIdNumberValidation(id: any, number: any) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/tcPatientEnrollment/validateIdProof?docType=${id}&idNumber=${number}`,
      false
    );
  }

  addRecordInfusion(recordDate: FormData) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/papScheme/updateRecordDate', recordDate, true);
  }

  uploadInfusionSend(uploadInfusion: FormData) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/papScheme/uploadDocument', uploadInfusion, true);
  }

  getRecordInfusionById(pitSeqGen: any, productId: any, pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/papScheme/getRecordInfusion?pitSeqGen=${pitSeqGen}&productId=${productId}&pcsId=${pcsId}`,
      false
    );
  }

  addPlaceOrder(placeOrder: any) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/papScheme/v2/placeOrder', placeOrder, false);
  }

  getPlaceOrderById(pitSeqGen: any, productId: any, pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/papScheme/getPlaceOrder?pitSeqGen=${pitSeqGen}&productId=${productId}&pcsId=${pcsId}`,
      false
    );
  }
  getPapUploadDoc(pitSeqGen: any, pcsId: any, productId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/papScheme/getPaidInvoiceDetails?pitSeqGen=${pitSeqGen}&pcsId=${pcsId}&productId=${productId}`,
      false
    );
  }

  getAllIndicationData(data: any) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/pcs/tcPatientEnrollment/indicationByDiseaseTypeId?diseaseTypeId=' + data,
      false
    );
  }

  getAllClusterDataByState(data: any) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/pcs/tcPatientEnrollment/clusterByStateName?stateName=' + data,
      false
    );
  }

  getAllInfusionData() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/flagsNumbersStatus', false);
  }

  getAllClusterData() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/clusters', false);
  }

  getReasonsForNotENrolled() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/reasonsForNotEnrolled', false);
  }

  getReasonsForDropout() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/dropoutReasons', false);
  }

  getAllIndications() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/indication', false);
  }

  getAllPatientsList(patient: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortKey: string;
    enrollIdList: any;
  }): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/common/getPatients', patient, false);
  }

  getAdminRolesList(adminRoles: any) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/common/getEmployees', adminRoles, false);
  }

  camundaGetAdminRolesList(adminRoles: any) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/common/getWorkflowEmployees', adminRoles, false);
  }

  getAllExceptionsList(list: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortKey: string;
    enrollIdList?: (
      | string
      | ExceptionsIdsEnum.Pending
      | ExceptionsIdsEnum.On_hold
      | ExceptionsIdsEnum.Approved
      | ExceptionsIdsEnum.Rejected
    )[];
    patientStatusList?: string[];
  }) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/common/getPatients', list, false);
  }

  getAdminExceptionHistory(exceptionDetails: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortKey: string;
  }) {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      '/common/getExceptionsHistory',
      exceptionDetails,
      false
    );
  }

  getAdminClusterId() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/tcPatientEnrollment/clusters', false);
  }

  getPatientNotificationCount() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '', false);
  }

  getPatientStatusCounts(): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/common/getPatientsCount', false);
  }

  getPatientDetailsByTbtId(pcsId: string): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/getPatientDetails/' + pcsId,
      false
    );
  }

  addActionDetails(body: any): Observable<any> {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/patientSummary/actions',
      body,
      false
    );
  }

  changeRequestHCPHCO(obj: any): Observable<any> {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/update/requested-doctor-hospital',
      obj,
      false
    );
  }

  getEmployeesByRoleId(roleId: string): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/common/userByRoleId?roleId=' + roleId, false);
  }

  getTcUsersById(roleId: string): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/common/tcUsersByEmployeeId?employeeId=' + roleId,
      false
    );
  }

  raiseConcern(raiseConcern: {
    comments: any;
    pcsId: any;
    mandatoryDocument:
      | { docId: any }
      | {
          docId: any; //       name +
        };
    flag: any;
  }) {
    return this.httpWrapperService.postTextRequest(
      'enrollmentAPI',
      `/tcPatientEnrollment/raiseConcern`,
      raiseConcern,
      false
    );
  }

  deleteMandateDoc(doc: any, redact: boolean): Observable<any> {
    return this.httpWrapperService.deleteRequest(
      'enrollmentAPI',
      `/pcs/document/mobile/removeDoc?sl_no=${doc}&isRedact=${redact}`,
      false
    );
  }

  getDistributorStatusCounts(): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/common/getDistributionCount', false);
  }

  getOrderStatusCount(): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/common/getDistributionCount?nonDistributorApi=true',
      false
    );
  }

  addCommonComments(comment: string, pcsId: string) {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      '/common/addPatientCommentByPcsId/' + pcsId,
      comment,
      false
    );
  }

  getCommentsHistory(pcsId: string, roles: string | CommentsRolesEnum.supervisor | CommentsRolesEnum.distributor) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/common/getPatientCommentHistory/' + pcsId + '/' + roles,
      false
    );
  }

  getDocCommentsHistory(
    pcsId: string,
    docId: string,
    roles: string | CommentsRolesEnum.supervisor | CommentsRolesEnum.distributor
  ) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/common/getPatientDocCommentHistory/' + pcsId + '/' + docId + '/' + roles,
      false
    );
  }

  downloadFile(docId: string, slNo: string, pcsId: string | null) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/pcs/document/v2/downloadDoc?docId=${docId}&slNo=${slNo}&pcsId=${pcsId}`,
      false
    );
  }

  downloadRedactFile(docId: string, slNo: string, pcsId: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/pcs/document/v2/downloadDoc?docId=${docId}&slNo=${slNo}&pcsId=${pcsId}&docType=redact`,
      false
    );
  }

  // end distributor services

  supActions(pcsId: any, action: any, comment: string | number | boolean, docNumbers: string): Observable<any> {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      `/tcPatientEnrollment/completeEnrollment/${pcsId}/${action}?comments=${encodeURIComponent(
        comment
      )}&docIds=${docNumbers}`,
      '',
      false
    );
  }

  supActionsDistributor(
    pcsId: any,
    action: any,
    comment: string | number | boolean,
    distributorId: any
  ): Observable<any> {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      `/tcPatientEnrollment/completeEnrollment/${pcsId}/${action}?comments=${encodeURIComponent(
        comment
      )}&distributorId=${distributorId}`,
      '',
      false
    );
  }
  
  // Roche admin Exception
  getExceptionDetails(pcsId: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/getPatientDetails/' + pcsId,
      false
    );
  }
  getOtherStatus(tId: string) {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/tcPatientEnrollment/getPatientStatus/' + tId, false);
  }

  sendHcp(form: any) {
    this.subjectHcpForm.next(form);
  }
  sendHcpStates(statesList: any) {
    this.subjectStatesList.next(statesList);
  }

  getHcp(): Observable<any> {
    return this.subjectHcpForm.asObservable();
  }

  getHcpStates(): Observable<any> {
    return this.subjectStatesList.asObservable();
  }

  resetDeletePerInfusion(pitSeqGen: any, pcsId: any, isDelete: any): Observable<any> {
    return this.httpWrapperService.postRequest(
      'enrollmentAPI',
      `/papScheme/resetInfusionByPitSeqGen?pitSeqGen=${pitSeqGen}&pcsId=${pcsId}&isRemoveInfusion=${isDelete}`,
      '',
      false
    );
  }

  clearInfusions(obj: { productId: any; pcsId: any }): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', `/papScheme/remove-infusions`, obj, false);
  }

  uploadCouponDoc(docObj: any) {
    return this.httpWrapperService.postRequest('enrollmentAPI', `/pcs/document/mobile/uploadMultipleDoc`, docObj, true);
  }

  getEnrolledFlag(pcsId: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/tcPatientEnrollment/getEnrolledFlagByPcsId?pcsId=${pcsId}`,
      false
    );
  }

  getDocHistory(pcsId: any, productId: any) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/common/getPatientDocHistory/${pcsId}/${productId}`,
      false
    );
  }

  getSKUProductList() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/papScheme/skuProductsList', false);
  }

  getAllRoles() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/public/web/roles', false);
  }

  getProfileInfo(pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', `/pcs/api/mobile/patient/profile?pcsId=${pcsId}`, false);
  }

  getIdProofList() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/pcs/enrollment/docTypes', false);
  }

  getAllAgenciesDetails(): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/common/psp-vendor', false);
  }

  getAllVendorList(obj: any): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/vendor/getAllVendor', obj, false);
  }

  addNewVendor(vendorObj: Object): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/vendor', vendorObj, false);
  }

  updateVendorStatus(vendorId, status) {
    return this.httpWrapperService.postRequest('enrollmentAPI', `/vendor/delete?vendorId=${vendorId}&isDelete=${status}`, '', false);
  }

  getVendorDataById(id:any){
    return this.httpWrapperService.getRequest('enrollmentAPI',`/vendor/${id}`,false)
  }
  updateVendorDataById(id:any, obj:any){
    return this.httpWrapperService.postRequest('enrollmentAPI',`/vendor/update/${id}`, obj,false)
  }
  
  getAllProgramsList(obj: {
    pageNo: number;
    pageSize: number;
    searchKeys: any;
    searchValue: any;
    sortBy : string;
    sortOrder: string;
    sortKey: string;
  }): Observable<any> {  
    return this.httpWrapperService.postRequest('enrollmentAPI', '/programs/getAllPrograms', obj, false);
  }

  getAllTenantsList(obj: {
    pageNo: number;
    pageSize: number;
    searchKeys: any;
    searchValue: any;
    sortBy : string;
    sortOrder: string;
    sortKey: string;
  }): Observable<any> {  
    return this.httpWrapperService.postRequest('enrollmentAPI', '/getAllTenants', obj, false);
  }

  getAllPrograms(){
    return this.httpWrapperService.getRequest('enrollmentAPI', `/programs`, false);
  }

  getDecisions(){
    return this.httpWrapperService.getRequest('enrollmentAPI', `/decisions`, false);
  }

  getVendorsList(obj: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortKey: string;
  }): Observable<any> {  
    return this.httpWrapperService.postRequest('enrollmentAPI', '/programs/getVendorList', obj, false);
  }
  
  addNewProgram(programObj: Object): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/programs', programObj, false);
  }
  updateProgram(programObj: Object, programId): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', `/programs/${programId}`, programObj, false);
  }

  addNewTenant(programObj: Object): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/programs', programObj, false);
  }
  updateTenant(programObj: Object, programId): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', `/programs/${programId}`, programObj, false);
  }


  getAdminProgram(programId) {
    return this.httpWrapperService.getRequest('enrollmentAPI', `/programs/${programId}`, false);
  }

  changeProgramStatus(ProgramsId, status) {  
    return this.httpWrapperService.postRequest('enrollmentAPI', `/programs/delete/?programId=${ProgramsId}&isDelete=${status}`, '', false);
  }

  // getAllPrograms(vendorId): Observable<any> {
  //   return this.httpWrapperService.getRequest('enrollmentAPI', `/programs/program/${vendorId}`, false);
  // }
  getAllSchemeList(obj: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortKey: string;
  }): Observable<any> {  
    return this.httpWrapperService.postRequest('enrollmentAPI', '/programs/getAllPrograms', obj, false);
  }

  getAllVendorsList(){
    return this.httpWrapperService.getRequest('enrollmentAPI',`/vendor/vendorsList`,false)
  }
 
}