import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { environment } from 'src/environments/environment';
import { HttpWrapperService } from './http-wrapper.service';
import { AlertService } from './alert.service';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root',
})
export class CmsService {
  cmsData: any;
  tenantId: any;
  private _config: any;
  private cmsContent$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private cmsPreContent$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  // private languageChangeSource = new Subject<boolean>();
  // languageChange$ = this.languageChangeSource.asObservable();

  token: any;
  langCode: any;
  tenantDetails: any;
  @BlockUI()
  blockUI!: NgBlockUI;

  constructor(
    private httpClient: HttpClient,
    private httpWrapperService: HttpWrapperService,
    private alertService: AlertService,
    private storageService: StorageService
  ) {
    this.getTenant();
  }

  async getTenant() {
    // *** Load pre login CMS data &  GET TENANT AND CMS TENANT ID"S** //
    let hostName = location.hostname;
    this.blockUI.start('Loading...');
    let tenantManagementAPI = environment.config.tenantManagementAPI;
    let tenantUrl = tenantManagementAPI + '/tenant/tenantHost/' + hostName;
    if (hostName == 'localhost') {
      this.blockUI.stop();
      this.storageService.storeData('X-tenantID', 'india');
      this.storageService.storeData('cmsTenantID', 'tenant1');
      const cmsTenantId = this.storageService.getData('cmsTenantID');
      this.token = this.storageService.getData('token');
      
      if (this.token) {
        const langCode = this.storageService.getData('langCode');
        this.getCmsContentReload(cmsTenantId, langCode);
        this.getPreLoginContentReload(cmsTenantId, langCode);
      } else if (!this.token) {
        this.getPreCmsContentData(cmsTenantId);
        this.storageService.storeData('langCode', 'en');
      }
    } else {
      await this.httpClient
        .get(tenantUrl)
        .toPromise()
        .then((res: any) => {
          this.blockUI.stop();
          this.storageService.storeData('X-tenantID', res?.tenantName);
          this.storageService.storeData('cmsTenantID', res?.ctenantName);
          this.token = this.storageService.getData('token');
          const cmsTenantId = this.storageService.getData('cmsTenantID');
          this.token = this.storageService.getData('token');
          if (this.token) {
            const langCode = this.storageService.getData('langCode');
            this.getCmsContentReload(cmsTenantId, langCode);
            this.getPreLoginContentReload(cmsTenantId, langCode);
          } else if (!this.token) {
            this.getPreCmsContentData(cmsTenantId);
            this.storageService.storeData('langCode', 'en');
          }
        })
        .catch((err: any) => {
          this.blockUI.stop();
          this.alertService.redirect('/500');
        });
    }
  }

  //****START  CMS PRE LOGIN  ****
  private getPreCmsContentData(cmsTenantId: string) {
    this.blockUI.start('Loading...');
    this.httpWrapperService
      .getCMSRequest(
        'cmsAPI',
        `/roche-multi-site/${cmsTenantId}/en/prelogincategories?category_names=labels,buttons,placeholders,validation,images,titles,subtitles`,
        true
      )
      .subscribe((res: any) => {
        this.blockUI.stop();
        this.setCmsPreData(res);
      },
        (error: any) => {
          this.blockUI.stop();
          this.httpWrapperService
            .getCMSRequest(
              'cmsOfflineAPI',
              `/roche-multi-site/${cmsTenantId}/en/prelogincategories?category_names=labels,buttons,placeholders,validation,images,titles,subtitles`,
              true
            )
            .subscribe((res: any) => {
              this.setCmsPreData(res);
            });
        })
  }

  private setCmsPreData(data: any) {
    this.cmsPreContent$.next(data);
  }

  getCMSPreContent(): Observable<any> {
    return this.cmsPreContent$.pipe(
      map((item) => {
        return item;
      })
    );
  }
  // *** END***

  //****START  CMS POST  LOGIN  ****
  private getCmsContentReload(cmsTenantId: string, languageCode: string) {
    this.httpWrapperService
      .getCMSRequest(
        'cmsAPI',
        `/roche-multi-site/${cmsTenantId}/${languageCode}/categories?category_names=labels,pageheadingsandparagraphs,nodata,sectiontitles,pagetitles,placeholders,breadcrumblist,navbarlabels,buttons,tableheadings,tabsandpills,cardtitles,requiredvalidations,imagesandicons,videos`,
        true
      )
      .subscribe((res: any) => {
        this.setCmsData(res);
      },
        (error: any) => {
          this.httpWrapperService
            .getCMSRequest(
              'cmsOfflineAPI',
              `/roche-multi-site/${cmsTenantId}/${languageCode}/categories?category_names=labels,pageheadingsandparagraphs,nodata,sectiontitles,pagetitles,placeholders,breadcrumblist,navbarlabels,buttons,tableheadings,tabsandpills,cardtitles,requiredvalidations,imagesandicons,videos`,
              true
            )
            .subscribe((res: any) => {
              this.setCmsData(res);
            });
        });
  }

  //****START  CMS PRE LOGIN  LOGIN  ****
  private getPreLoginContentReload(cmsTenantId: string, languageCode: string) {
    this.httpWrapperService
    .getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/en/prelogincategories?category_names=labels,buttons,placeholders,validation,images,titles,subtitles`,
      true
    )
    .subscribe((res: any) => {
      this.blockUI.stop();
      this.setCmsPreData(res);
    },
      (error: any) => {
        this.blockUI.stop();
        this.httpWrapperService
          .getCMSRequest(
            'cmsOfflineAPI',
            `/roche-multi-site/${cmsTenantId}/en/prelogincategories?category_names=labels,buttons,placeholders,validation,images,titles,subtitles`,
            true
          )
          .subscribe((res: any) => {
            this.setCmsPreData(res);
          });
      })
  }

  getCmsContentData(CMSAPI, tenantId: string, languageCode: string) {
    return this.httpWrapperService.getCMSRequest(
      `${CMSAPI}`,
      `/roche-multi-site/${tenantId}/${languageCode}/categories?category_names=labels,pageheadingsandparagraphs,nodata,sectiontitles,pagetitles,placeholders,breadcrumblist,navbarlabels,buttons,tableheadings,tabsandpills,cardtitles,requiredvalidations,imagesandicons,imagesandicons`,
      true
    );
  }

  setCmsData(data: any) {
    this.cmsContent$.next(data);
  }

  getCMSContent(): Observable<any> {
    return this.cmsContent$.pipe(
      map((item) => {
        return item;
      })
    );
  }
  // *** END***

  getTermsAndConditions(cmsTenantId, langCode, pageKey: string) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/${pageKey}`,
      true
    );
  }

  getPrivacyAndPolicy(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/privacy-policy`,
      true
    );
  }

  getSupport(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/support`,
      true
    );
  }

  getProductsInfo(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/wp/v2/${langCode}/helpinformation`,
      true
    );
  }

  getHelp(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/help`,
      true
    );
  }

  getHome(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/home`,
      true
    );
  }

  getDiseaseAwareness(cmsTenantId, langCode, keyValue: string) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/${keyValue}`,
      true
    );
  }

  getPatientHelp(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/roche/v1/pages/${langCode}/patient-help`,
      true
    );
  }
  getFinancialCounselling(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/wp/v2/${langCode}/financialcounsellingfaqs`,
      true
    );
  }

  getFinancialCounsellingFaqs(cmsTenantId, langCode) {
    return this.httpWrapperService.getCMSRequest(
      'cmsAPI',
      `/roche-multi-site/${cmsTenantId}/wp-json/wp/v2/${langCode}/faqs`,
      true
    );
  }
}
