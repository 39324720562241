import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfig } from 'src/app/core/configs/app.configs';
import { HttpWrapperService } from 'src/app/core/services/http-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  token = localStorage.getItem('token');
  private baseAPI: any;
  private adminBaseAPI: any;
  private subjectHcpForm = new Subject<any>();
  private subjectStatesList = new Subject<any>();
  private papBaseAPI: any;
  private baseAPI_PCS: any;
  httpOptions: any;
  httpOptionsFormData: any;
  httpOptionsSupActions: any;
  httpPAPOptions: any;
  headerDict: any;
  distributorBaseAPI: any;
  xTenantID: any;

  constructor(
    private _config: AppConfig,
    private httpWrapperService: HttpWrapperService,
    private http: HttpClient
  ) {
    this.baseAPI = _config.get('baseAPI');
    this.papBaseAPI = _config.get('papBaseAPI');
    this.adminBaseAPI = _config.get('adminBaseAPI');
    this.distributorBaseAPI = _config.get('distributorBaseAPI');
    this.baseAPI_PCS = _config.get('papBaseAPI');
  }

  getHttpOptions() {
    let token = localStorage.getItem('token');
    this.xTenantID = localStorage.getItem('X-tenantID');
    return (this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-VendorID': '1',
        'X-TenantID':  this.xTenantID,
      }),
    });
  }

  getDynamicTabForms(formCategory: any) {
    return this.httpWrapperService.getRequest('baseAPI_PCS', `/forms/formCategory/${formCategory}`, false);
  }
  ageAutoCal(selectedDate: any) {
    const today = new Date();
    const birthDate = selectedDate;
    let age = today.getFullYear() - birthDate.year;
    const m = today.getMonth() + 1 - birthDate.month;
    const d = today.getDate() - birthDate.day;
    if (m < 0 || (m === 0 && today.getDate() < birthDate.day)) {
      if (d < 0) {
        age--;
      }
    }
    return age;
  }
  getTcPatinetDetails(pcsId: string) {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      '/tcPatientEnrollment/getPatientDetails/' + pcsId,
      false
    );
  }
  getState() {
    return this.httpWrapperService.getRequest('apiURL', '/stateDto/', false);
  }
  getCity() {
    return this.httpWrapperService.getRequest('apiURL', '/cityDto/', false);
  }

  errorHandler(error: { error: { message: string }; status: any; message: any }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  timeConversion(time: string | number | Date) {
    const dateOnline = new Date(time);
    const locdat = new Date(dateOnline + ' UTC');
    return locdat;
  }

  addNewLeadOthersSave(otherDetails: any): Observable<any> {
    console.log(otherDetails);
    return this.http
      .post('enrollmentAPI' + '/tcPatientEnrollment/savePatientDetails', otherDetails, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getPermissionDetails(): Observable<any> {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/permissions', false);
  }

  getHippaAllVendorList(data: any) {
    return this.http.post('https://dev-services.patientcaresolution.com/api/pcs-enrollment/hippa/get-all-vendors', data, this.getHttpOptions());
  }

  addHippaVendor(data: any) {
    return this.http.post('https://dev-services.patientcaresolution.com/api/pcs-enrollment/hippa/save-vendor', data, this.getHttpOptions());
  }

}
