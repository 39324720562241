import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageService } from 'src/app/core/services/storage.service';
import { PcsUserManagementService } from 'src/app/core/services/pcs-user-management.service';
import { CryptoService } from 'src/app/core/services/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  storageService: StorageService;
  userManagementService: PcsUserManagementService;
  constructor(private router: Router, private inject: Injector, private crypto: CryptoService) {
    this.userManagementService = this.inject.get(PcsUserManagementService);
    this.storageService = this.inject.get(StorageService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = this.crypto.aesDecrypt(this.storageService.getData('role') || '');
    if (!role) {
      this.userManagementService.logout();
    } else if (role && route.data?.['roles'].includes(role)) {
      return true;
    } else if (role && !route.data?.['roles'].includes(role)) {
      this.router.navigate(['/patient-list']);
    }
    return false;
  }
}
