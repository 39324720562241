import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpWrapperService } from './services/http-wrapper.service';
import { AlertService } from './services/alert.service';
import { AppUtilityService } from './services/app-utility.service';
import { CryptoService } from './services/crypto.service';
import { LoggerService } from './services/logger.service';
import { AuthService } from './services/auth.service';
import { CmsService } from './services/cms.service';
import { PatientService } from '../features/services/patient.service';
import { PatientWebService } from '../patient/services/patient-web.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    HttpWrapperService,
    AlertService,
    AppUtilityService,
    LoggerService,
    CryptoService,
    PatientWebService,
    AuthService,
    PatientService,
    CmsService,
  ],
})
export class CoreModule {}
