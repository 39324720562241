import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
// -- Language
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppConfig } from './core/configs/app.configs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// -- Modules
import { CoreModule } from './core/core.module';

// -- Services
import { HttpWrapperService } from './core/services/http-wrapper.service';
import { AlertService } from './core/services/alert.service';
import { AppUtilityService } from './core/services/app-utility.service';
import { LoggerService } from './core/services/logger.service';
import { PcsUserManagementService } from './core/services/pcs-user-management.service';
import { CommonService } from './shared/services/common.service';

// -- AuthGuard
import { AuthGuard } from './core/guards/auth.guard';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

// -- Layout
import { LayoutComponent } from './core/components/layout/layout.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { ForgotPasswordComponent } from './shared/components/common/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './shared/components/common/change-password/change-password.component';
import { PasswordCreationComponent } from './shared/components/common/password-creation/password-creation.component';
import { FeedbackComponent } from './shared/components/common/feedback/feedback.component';
import { NgxPrintElementModule } from 'ngx-print-element';

//-- Initialize Configuration
export function initConfig(configObj: AppConfig) {
  return () => configObj.loadAppEnv();
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    PasswordCreationComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    FeedbackComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    NgSelectModule,
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    NgbNavModule,
    NgxPrintElementModule
  ],
  providers: [
    HttpClient,
    AppConfig,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
    HttpWrapperService,
    AlertService,
    AppUtilityService,
    LoggerService,
    PcsUserManagementService,
    AuthGuard,
    CommonService,
  ],
  exports: [RouterModule, LayoutComponent, CoreModule],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
