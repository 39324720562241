import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/core/configs/app.configs';

import { HttpWrapperService } from 'src/app/core/services/http-wrapper.service';
const refreshToken = 'auth-refreshtoken';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userManagementAPI: any;
  token = localStorage.getItem('token');
  currentRole: any;

  constructor(private _config: AppConfig, private httpWrapperService: HttpWrapperService) {
    this.userManagementAPI = _config.get('userManagementAPI');
    this.currentRole = localStorage.getItem('role');
  }

  // moved to user service

  public getRenewAccessToken(refreshToken: string) {
    return this.httpWrapperService.getRequest(
      'userManagementAPI',
      '/authentication/web/refreshToken/' + refreshToken,
      false
    );
  }

  public handleRefreshTokenResponse(res: any) {
    localStorage.removeItem(refreshToken);
    return localStorage.getItem(refreshToken);
  }
}
