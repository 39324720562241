import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  Observable,
  Subscription,
  throwError,
} from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import jwtDecode from 'jwt-decode';

import { AuthService } from 'src/app/core/services/auth.service';
import { PcsUserManagementService } from 'src/app/core/services/pcs-user-management.service';
import { AppUtilityService } from 'src/app/core/services/app-utility.service';
import { Auth } from 'src/app/features/models/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  countDown: Subscription = new Subscription();
  counter = 0;
  tick = 0;
  loadercountdown = false;
  intervalSubscription: Subscription = new Subscription();

  auth: Auth = new Auth();
  roleOptions: any = [];
  @BlockUI()
  blockUI!: NgBlockUI;
  alertSubscribe: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private utility: AppUtilityService,
    private userManagementService: PcsUserManagementService,
    private authService: AuthService
  ) {
    this.counter = 60;
    this.tick = 1000;
  }

  /**
   * Intercept the all request to check whether Auth header need to be added
   * @param req Http Request
   * @param next Next Handler in Http Chain
   */

  // ----------------------- 1st approach

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    if (this.isAuthenticatedApi(req.url)) {
      const accessToken = localStorage.getItem('token'); // 'accessToken';
      const refreshToken = localStorage.getItem('refreshToken'); // 'refreshToken';

      if (accessToken && refreshToken) {
        const tokenExp: any = jwtDecode(localStorage.getItem('token') || '');
        if (new Date(tokenExp.exp * 1000).valueOf() - 30000 <= new Date().valueOf()) {
          return this.handleUnauthorized(req, next, localStorage.getItem('refreshToken') || '');
        }
        return next.handle(this.addAccessToken(req, accessToken)).pipe(
          catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
              console.log(err.status);
              if (err.status === 401) {
                console.log('401');
                return this.handleUnauthorized(req, next, refreshToken);
              }
            }
            // Rethrow error so caller component can handle this
            return throwError(()=>new Error(err));
          })
        );
      }

      this.redirectToLoginPage();
    } else {
      return next.handle(req);
    }
  }

  /**
   * Check whether Authorization header in the form of Bearer Token needs to be sent.
   *
   * @param path - Request URL Path
   */

  private isAuthenticatedApi(path: string): boolean {
    return !this.utility.isInvalidData(path) &&
      !path.includes('/login') &&
      !path.includes('/validateOtp') &&
      !path.includes('/relationshipType') &&
      !path.includes('/states?countryId') &&
      !path.includes('/cities?stateId') &&
      !path.includes('/mobile/signup') &&
      !path.includes('/refreshtoken') &&
      !path.includes('/assets') &&
      !path.includes('/public/web/roles') &&
      !path.includes('/public/web/userByEmployeeId') &&
      !path.includes('/pcs/api/create/pasword') &&
      !path.includes('/pcs/api/forgot/pasword') &&
      !path.includes('/service-list') &&
      !path.includes('/patient-foc/savefeedback-details') &&
      !path.includes('/patient-enrollment/feedback-details') &&
      !path.includes('/patient-vas/feedback-details') &&
      !path.includes('/getFeedbackQuestionariesByFeedbackCategoryId') &&
      !path.includes('/roche-multi-site/') &&
      !path.includes('/api/pcs-tenant-mgmt/tenant/tenantHost/')&&
      !path.includes('/api/pcs-tenant-mgmt/tenantOnBoarding') &&
      !path.includes('/pcs-user-mgmt/signup') 
      ? true
      : false;
  }
  

  private handleUnauthorized(req: HttpRequest<any>, next: HttpHandler, refreshToken: string): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next('');
      // get a new token via authService.renewAccessToken
      return this.authService.getRenewAccessToken(refreshToken).pipe(
        switchMap((res: any) => {
          this.authService.handleRefreshTokenResponse(res);
          const newToken = res.refreshToken;
          // did we get a new token retry previous request
          if (newToken) {
            localStorage.setItem('token', res.accessToken);
            localStorage.setItem('refreshToken', res.refreshToken);
            this.tokenSubject.next(newToken);
            return next.handle(this.addAccessToken(req, res.accessToken));
          }
          // If we don't get a new token, so logout.
          this.redirectToLoginPage();
          console.log('no token');
          return throwError(()=>new Error('test'));
        }),
        catchError((error: any) => {
          // If there is an exception calling 'renewAccessToken', so logout.
          this.isRefreshingToken = false;
          return Promise.reject(error);
        }),
        finalize(() => {
          console.log('finalize');
          this.isRefreshingToken = false;
        })
      );
    } else {
      console.log('after refreshing');
      return this.tokenSubject.pipe(
        filter((token: any) => token != null),
        take(1),
        switchMap((token: any) => {
          return next.handle(this.addAccessToken(req, token));
        })
      );
    }
  }

  /**
   * Update the original request with New Access Token
   * @param request Original HttpRequest
   * @param token New Access Token
   */

  private addAccessToken(request: HttpRequest<any>, token: any): any {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }

  private redirectToLoginPage() {
    this.userManagementService.logout();
  }
}
