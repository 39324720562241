export enum PatientStatusEnum {
  Pre_lead = 'Pre Lead',
  Lead = 'Lead',
  Pending_review = 'Pending review',
  Onhold = 'Onhold',
  Drop_out = 'Dropout',
  Not_enrolled = 'Not Enrolled',
  Reject = 'Reject',
  Enrolled = 'Enrolled',
  theraphy_completed = 'Therapy Completed',
  pendingSignOff = 'Pending Signoff',
  requestException = 'Request Exception',
  requestExceptionApproved = 'Request Exception Approved',
  requestExceptionOnHold = 'Request Exception On Hold',
  requestExceptionReject = 'Request Exception Reject',
  toBeDispatched = 'To Be Dispatched',
  Dispatched = 'Dispatched',
  Delivered = 'Delivered',
  Pending = 'Pending',
  Approved = 'Approved',
  Deactivate = 'Deactive',
  All = 'All',
  distributorToBeDispatched = 'To Be Dispatched',
  distributorDispatched = 'Dispatched',
  distributorDelivered = 'Delivered',
  cancel = 'Cancel',
  distributorOnhold = 'Onhold',
  distributorNotDelivered = 'Not Delivered',
  distributorPending = 'Pending',
  postFoc = 'Post Enrollment ReOrder',
  onHold = 'On Hold',
  Completed = 'Completed',
  InProgress = 'In Progress',
  Rejected = 'Rejected',
  Review = 'Review',
  MissedTherapy = 'Missed Therapy',
}

export enum PatientStatusIdsEnum {
  Pre_lead = 1,
  Lead = 2,
  Pending_review = 'Pending review',
  Onhold = 4,
  Drop_out = 5,
  Not_enrolled = 6,
  Reject = 7,
  Enrolled = 8,
  theraphy_completed = 9,
  Exception_pre_enrollment = 17,
  pendingSignOff = 13,
  others = 19,
  requestException = 10,
  requestExceptionApproved = 11,
  requestExceptionOnHold = 15,
  requestExceptionReject = 14,
  toBeDispatched = 'To Be Dispatched',
  Dispatched = 'Dispatched',
  Delivered = 'Delivered',
  Pending = 3,
  Approved = 'Approved',
  Deactivate = 'Deactive',
  All = 'All',
  distributorToBeDispatched = 4,
  distributorDispatched = 5,
  distributorDelivered = 3,
  cancel = 7,
  distributorOnhold = 1,
  distributorNotDelivered = 6,
  distributorPending = 2,
  postFoc = 18,
  missedTherapy = 12,
}

export enum RoleEnum {
  Field_Counsellor = 'Field Counsellor',
  Telle_Caller = 'Tele Caller',
  Team_Lead = 'Team Lead',
  Distributor = 'Distributor',
  Supervisor = 'Supervisor',
  Administrator = 'Administrator',
  SuperAdmin = 'Super Admin',
  RocheAdmin = 'Roche Admin',
  Provider = 'Provider',
  Migration_User = 'Migration_User',
  Doctor = 'Doctor',
  Prod_user = 'Prod_user',
  Patient = 'Patient',
  BranchDistributor = 'Branch Distributor',
  Hospital = 'Hospital',
  CareCoordinater = 'Care Coordinater',
  CareCoordinaterManager = 'Care Coordinater Manager',
  MouriSuperAdmin = 'Mouri Super Admin'
}
export enum InfusionStatusEnum {
  Upload_Infusion_Summary = 1,
  Documents_Uploaded = 2,
  Complete = 3,
  Request_For_Foc = 4,
  Inprogress = 5,
  Request_Foc_Exception = 6,
  Request_Foc_Approved = 7,
  Virtual_Order_Created = 8
}

export enum StageTypeEnum {
  Test_booked = 'Test booked',
  Sample_received = 'Sample received',
  Report_generated = 'Report generated',
  Slot_confirmed = 'Slot confirmed',
  Contact_Established = 'Contact Established',
  Documents_Received = 'Documents Recieved',
  Funding_Rejected = 'Funding Rejected',
  Consent_Received = 'Consent Received',
  Design_Inprogress = 'Design-in-progress',
  Campaign_Live = 'Campaign Live',
  Not_Contactable = 'Not Contactable',
  Unwilling = 'Unwilling',
  Loan_Approved = 'Approved',
  Loan_Rejected = 'Rejected',
  Sanctioned = 'Sanctioned',
  Disbursed = 'Disbursed',
  Interested = 'Interested',
}

export enum ExceptionsEnum {
  All = 'All',
  Pending = 'Request Exception',
  On_hold = 'Request Exception On Hold',
  Approved = 'Request Exception Approved',
  Rejected = 'Request Exception Reject',
}

export enum ExceptionApproversEnum {
  EXCEPTION_APPROVE = 'EXCEPTION_APPROVE',
  EXCEPTION_ON_HOLD = 'EXCEPTION_ON_HOLD',
  EXCEPTION_REJECT = 'EXCEPTION_REJECT',
}

export enum ExceptionsIdsEnum {
  All = 'All',
  Pending = 10,
  On_hold = 15,
  Approved = 11,
  Rejected = 14,
}

export const DocumentTypes = [
  {
    docTypeId: 1,
    docTypeName: 'Aaadhar'
  },
  {
    docTypeId: 2,
    docTypeName: 'PAN'
  },
  {
    docTypeId: 3,
    docTypeName: 'Voter ID'
  },
  {
    docTypeId: 4,
    docTypeName: 'Passport'
  },
  {
    docTypeId: 5,
    docTypeName: 'Driving License'
  }
];

export const DistributorTabsArray = [
  {
    displayName: 'All',
    selectedTabName: PatientStatusEnum.All,
    count: 0,
    enrollId: PatientStatusIdsEnum.All,
  },
  {
    displayName: 'To be dispatched',
    selectedTabName: PatientStatusEnum.distributorToBeDispatched,
    count: 0,
    enrollId: PatientStatusIdsEnum.distributorToBeDispatched,
  },
  {
    displayName: 'Dispatched',
    selectedTabName: PatientStatusEnum.distributorDispatched,
    count: 0,
    enrollId: PatientStatusIdsEnum.distributorDispatched,
  },
  {
    displayName: 'Onhold',
    selectedTabName: PatientStatusEnum.distributorOnhold,
    count: 0,
    enrollId: PatientStatusIdsEnum.distributorOnhold,
  },
  {
    displayName: 'Cancel',
    selectedTabName: PatientStatusEnum.cancel,
    count: 0,
    enrollId: PatientStatusIdsEnum.cancel,
  },
  {
    displayName: 'Delivered',
    selectedTabName: PatientStatusEnum.distributorDelivered,
    count: 0,
    enrollId: PatientStatusIdsEnum.distributorDelivered,
  },
  {
    displayName: 'Not Delivered',
    selectedTabName: PatientStatusEnum.distributorNotDelivered,
    count: 0,
    enrollId: PatientStatusIdsEnum.distributorNotDelivered,
  },
];

export const PatientListTabsArray = [
  {
    displayName: 'All',
    selectedTabName: PatientStatusEnum.All,
    count: 0,
    enrollId: PatientStatusIdsEnum.All,
  },
  {
    displayName: 'pre lead',
    selectedTabName: PatientStatusEnum.Pre_lead,
    count: 0,
    enrollId: PatientStatusIdsEnum.Pre_lead,
  },
  {
    displayName: 'leads',
    selectedTabName: PatientStatusEnum.Lead,
    count: 0,
    enrollId: PatientStatusIdsEnum.Lead,
  },
  {
    displayName: 'Pending review',
    selectedTabName: PatientStatusEnum.Pending,
    count: 0,
    enrollId: PatientStatusIdsEnum.Pending,
  },
  {
    displayName: 'Pending sign off',
    selectedTabName: PatientStatusEnum.pendingSignOff,
    count: 0,
    enrollId: PatientStatusIdsEnum.pendingSignOff,
  },
  {
    displayName: 'On Hold',
    selectedTabName: PatientStatusEnum.Onhold,
    count: 0,
    enrollId: PatientStatusIdsEnum.Onhold,
  },
  {
    displayName: 'Rejects',
    selectedTabName: PatientStatusEnum.Reject,
    count: 0,
    enrollId: PatientStatusIdsEnum.Reject,
  },
  {
    displayName: 'Enrolled',
    selectedTabName: PatientStatusEnum.Enrolled,
    count: 0,
    enrollId: PatientStatusIdsEnum.Enrolled,
  },
  // {
  //   displayName: 'To be dispatched',
  //   selectedTabName: PatientStatusEnum.toBeDispatched,
  //   count: 0,
  //   enrollId: PatientStatusIdsEnum.toBeDispatched
  // },
  // {
  //   displayName: 'Dispatched',
  //   selectedTabName: PatientStatusEnum.Dispatched,
  //   count: 0,
  //   enrollId: PatientStatusIdsEnum.Dispatched
  // },
  // {
  //   displayName: 'Delivered',
  //   selectedTabName: PatientStatusEnum.Delivered,
  //   count: 0,
  //   enrollId: PatientStatusIdsEnum.Delivered
  // },
];

export enum ServiceRequestStatusIdsEnum {
  Inprogress = 2,
  Complete = 3,
  Cancelled = 5,
}

export enum ServiceRequestStatusEnum {
  Inprogress = 'In Progress',
  Complete = 'Completed',
  Cancelled = 'Cancelled',
  All = 'All',
  Reschedule = 'Reschedule',
}

export enum ServiceDocIds {
  FinancialCounselling = '66',
  CrowdFunding = '67',
}

export enum ServiceRequestStagesEnum {
  TestBooked = 1,
  SlotConfirmed = 2,
  SampleReceived = 3,
  ReportGenerated = 4,
  Consent_Received = 5,
  DesignInProgress = 6,
  Campaign_Live = 7,
  Not_Contactable = 8,
  Unwilling = 9,
  Contact_Established = 10,
  Documents_Received = 11,
  Approved = 12,
  RejectedFinancial = 13,
  Sanctioned = 14,
  Disbursed = 15,
  RejectedCrowd = 16,
  InterestedFinancial = 17,
  // InterestedCrowd = 17,
}

export enum UserRoles {
  TeleCaller = 2,
  FieldCounsellor = 3,
  TeamLead = 4,
  Supervisor = 5,
  RocheAdmin = 6,
  SuperAdmin = 7,
  Distributor = 8,
  Migration_User = 11,
  Provider = 12,
  Doctor = 13,
  BranchDistributor = 15,
}

export enum ServiceProviderCatageories {
  DiagnosticSupport = 1,
  PsychoSocialCounselling = 2,
  NutritionalCounselling = 3,
  DiseaseAwareness = 4,
  Physiotherapy = 5,
  CrowdFunding = 6,
  FinancialCounselling = 7,
}

export const ServiceRequestCommentRoles = [
  { id: 1, name: 'PSP members' },
  { id: 2, name: 'Patient' },
];

export enum ServiceRequestRoleIds {
  PSPmembers = 1,
  Patient = 2,
}

export enum IsPatientComment {
  Yes = '1',
  No = '2',
  NA = '3',
}

export const ServiceRequestStausList = [
  {
    currentStatusId: 3,
    currentStatusDiscription: 'Completed',
    status: 'COMPLETED',
    disabled: false,
  },
  {
    currentStatusId: 5,
    currentStatusDiscription: 'Cancel',
    status: 'CANCELLED',
    disabled: false,
  },
];
export const ServiceRequestTabsArray = [
  {
    displayName: 'All',
    selectedTabName: ServiceRequestStatusEnum.All,
    count: 0,
    enrollId: ServiceRequestStatusEnum.All,
  },
  {
    displayName: 'Inprogress',
    selectedTabName: ServiceRequestStatusEnum.Inprogress,
    count: 0,
    enrollId: ServiceRequestStatusEnum.Inprogress,
  },
  // {
  //   displayName: 'Reschedule',
  //   selectedTabName: ServiceRequestStatusEnum.Reschedule,
  //   count: 0,
  //   enrollId: ServiceRequestStatusEnum.Reschedule,
  // },
  {
    displayName: 'Complete',
    selectedTabName: ServiceRequestStatusEnum.Complete,
    count: 0,
    enrollId: ServiceRequestStatusEnum.Complete,
  },
  {
    displayName: 'Cancelled',
    selectedTabName: ServiceRequestStatusEnum.Cancelled,
    count: 0,
    enrollId: ServiceRequestStatusEnum.Cancelled,
  },
];

export const exceptionsEnumListTabsArray = [
  {
    displayName: 'All',
    selectedTabName: ExceptionsEnum.All,
    count: 0,
  },
  {
    displayName: 'Pending',
    selectedTabName: ExceptionsEnum.Pending,
    count: 0,
  },
  {
    displayName: 'On hold',
    selectedTabName: ExceptionsEnum.On_hold,
    count: 0,
  },
  {
    displayName: 'Approved',
    selectedTabName: ExceptionsEnum.Approved,
    count: 0,
  },
  {
    displayName: 'Rejected',
    selectedTabName: ExceptionsEnum.Rejected,
    count: 0,
  },
];

export const RolesPermissionsListTabsArray = [
  {
    displayName: 'Field Counsellor',
    selectedTabName: RoleEnum.Field_Counsellor,
    count: 0,
  },
  {
    displayName: 'Tele Caller',
    selectedTabName: RoleEnum.Telle_Caller,
    count: 0,
  },
  {
    displayName: 'Team Lead',
    selectedTabName: RoleEnum.Team_Lead,
    count: 0,
  },
  {
    displayName: 'Supervisor',
    selectedTabName: RoleEnum.Supervisor,
    count: 0,
  },
  {
    displayName: 'Administrator',
    selectedTabName: RoleEnum.Administrator,
    count: 0,
  },
  {
    displayName: 'Distributor',
    selectedTabName: RoleEnum.Distributor,
    count: 0,
  },
  {
    displayName: 'Doctor',
    selectedTabName: RoleEnum.Doctor,
    count: 0,
  },
  {
    displayName: 'Hospital',
    selectedTabName: RoleEnum.Hospital,
    count: 0,
  },
];

export const ExceptionsListTabsArray = [
  {
    displayName: 'All',
    selectedTabName: ExceptionsEnum.All,
    count: 0,
    enrollId: ExceptionsIdsEnum.All,
  },
  {
    displayName: 'Pending',
    selectedTabName: ExceptionsEnum.Pending,
    count: 0,
    enrollId: ExceptionsIdsEnum.Pending,
  },
  {
    displayName: 'On hold',
    selectedTabName: ExceptionsEnum.On_hold,
    count: 0,
    enrollId: ExceptionsIdsEnum.On_hold,
  },
  {
    displayName: 'Approved',
    selectedTabName: ExceptionsEnum.Approved,
    count: 0,
    enrollId: ExceptionsIdsEnum.Approved,
  },
  {
    displayName: 'Rejected',
    selectedTabName: ExceptionsEnum.Rejected,
    count: 0,
    enrollId: ExceptionsIdsEnum.Rejected,
  },
];

export const VASListTabsArray = [
  {
    displayName: 'All',
    selectedTabName: 'All',
    count: 0,
    enrollId: 1,
  },
  {
    displayName: 'Active',
    selectedTabName: 'Active',
    count: 0,
    enrollId: 2,
  },
  {
    displayName: 'Inactive',
    selectedTabName: 'Inactive',
    count: 0,
    enrollId: 3,
  },
];

export const VASurl = {
  service: '/admin/vas-service-list',
  provider: '/admin/vas-provider-list',
};

export const PatientListColumsArray = [
  {
    displayName: 'TBT ID',
    selectedColumnName: 'pcsId',
  },
  {
    displayName: 'Enrollment date',
    selectedColumnName: 'enrollmentDate',
  },
  {
    displayName: 'Name',
    selectedColumnName: 'firstName',
  },
  {
    displayName: 'Phone',
    selectedColumnName: 'phoneNumber',
  },
  {
    displayName: 'Disease type',
    selectedColumnName: 'diseaseName',
  },
  {
    displayName: 'HCP',
    selectedColumnName: 'doctorName',
  },
  {
    displayName: 'Cluster',
    selectedColumnName: 'clusterName',
  },
  {
    displayName: 'Status',
    selectedColumnName: 'status',
  },
  {
    displayName: 'Last Updated',
    selectedColumnName: 'updatedAt',
  },
];

export enum ReportsNamesEnum {
  tatReport = 1,
  accountDistributionReport = 2,
  doctorDistributionReport = 3,
  sourceOfFundingDistributionReport = 4,
  diseaseIndicationWiseDistributionReport = 5,
  sourceOfVialDistributionReport = 6,
  languageDistributionReport = 7,
  pendencyReport = 8,
  diseaseIndicationProductDistributionReport = 9,
  reasonsForNotEnrolling = 11,
  reasonsForDropingOut = 12,
  stateOfResidence = 13,
  stateOfTreatment = 14,
  productVsCluster = 15,
  dispatchTracker = 16,
  misReport = 22,
  vasProviderFeedbackDetailedReport = 23,
  vasReport = 24,
  vasProviderFeedbackStarRatingCount = 25,
  vasProviderFeedbackAverageRating = 26,
  patientEnrollmentFeedbackReport = 27,
  patientFOCFeedbackDetailsReport = 28,
  financialCounselingReport = 29,
  crowdFundingReport = 30,
  overallCouponReport = 32,
  couponUtilizationReport = 33,
}
// distributor enum's
export enum DistributorStatusEnum {
  onhold = 1,
  pending = 2,
  delivered = 3,
  to_be_dispatched = 4,
  dispatched = 5,
  not_delivered = 6,
  cancel = 7,
}

export enum DeliveryTypeEnum {
  courier = 'courier',
  handDelivery = 'handDelivery',
}

export const DeliveryTypesEnum = [
  { value: 'courier', name: 'Courier' },
  { value: 'handDelivery', name: 'Hand Delivery' },
];

export const DeliveryStatusListEnum = [
  { value: '4', name: 'To be dispatched' },
  { value: '5', name: 'Dispatched' },
  { value: '3', name: 'Delivered' },
  { value: '6', name: 'Not delivered' },
  { value: '1', name: 'Onhold' },
  { value: '7', name: 'Cancel' },
];

export enum CommentsRolesEnum {
  ALL = 'ALL',
  supervisor = 5,
  distributor = 8,
}

export enum RolesHeaderEnum {
  Employee_ID = 'Employee Id',
  Name = 'Name',
  Email = 'Email',
  Contact_Number = 'Contact Number',
  Status = 'Status',
}

export const InfusionDocumentTypeEnum = [{ docId: 7, docName: 'Patient therapy summary card' }];

export const PaidInvoiceDocumentEnum = {
  docId: 13,
  docName: 'Paid Invoice',
  mandatoryLevel: 'true',
};

export enum DocumentTypeEnum {
  KYC_Docs_ID_Proof = '1',
  KYC_Docs_Address_Proof = '2',
  ID_proof_for_reimbersed_patients = '3',
  Patient_Doc_Consent_Forms = '4',
  Rx_Info_Doctor_Prescription = '5',
  PAP_Form = '6',
  Patient_Infusion_summary_card = '7',
  Miscellaneous = '8',
  paid_invoice = '13',
  counsellingDocs = '44',
  DiagnosticResult = '43',
  DiagnosticPrescription = '42',
  Patient_Doctor_consent_media_DoctorApp = '64',
  couponDoc = '68',
  focDoc = '69',
}

// Role Enum id's
export enum RoleIds {
  Telle_Caller = '2',
  Field_Counsellor = '3',
  Team_Lead = '4',
  Supervisor = '5',
  RocheAdmin = '6',
  SuperAdmin = '7',
  Distributor = '8',
  Provider = '12',
  Hospital = '16',
}

export enum Statuses {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export const DocumentTypeListFCEnum = [{ docId: 1, docName: 'Personal ID(Govt. issued)', mandatoryLevel: 'true' }];

export enum SortByColumnNameEnum {
  orderScheduledDate = 'orderScheduledDate',
  pcsId = 'pcsId',
  updatedAt = 'updatedAt',
}

export const FeedBackCategoryArray = [
  {
    feedbackCategoryId: 1,
    feedbackCategoryDescription: 'Enrollment',
  },
  {
    feedbackCategoryId: 2,
    feedbackCategoryDescription: 'FOC_delivery',
  },
  {
    feedbackCategoryId: 3,
    feedbackCategoryDescription: 'VAS',
  },
];

export enum DocumentSizes {
  logoSize = 4194304,
  generalFile = 10485760,
  video = 52428800,
}

export enum VasStatusIds {
  review = '1',
  inprogress = '2',
  completed = '3',
  rejected = '4',
  cancelled = '5',
  reschedule = '6',
}

export enum NotificationTabs {
  all = 'ALL',
  patientApp = 'PATIENT_APP',
  ops = 'OPS',
  doctorApp = 'DOCTOR_APP',
  therapy = 'THERAPY',
  proofOfDelivery = 'PROOF_OF_DELIVERY',
}

export const FCNotificationTabs = [
  {
    displayName: 'All',
    selectedTabName: 'ALL',
  },
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
  {
    displayName: 'Proof of Delivery',
    selectedTabName: 'PROOF_OF_DELIVERY',
  },
];

export const TCNotificationTabs = [
  {
    displayName: 'All',
    selectedTabName: 'ALL',
  },
  {
    displayName: 'Patient App',
    selectedTabName: 'PATIENT_APP',
  },
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
  {
    displayName: 'Doctor App',
    selectedTabName: 'DOCTOR_APP',
  },
  {
    displayName: 'Therapy',
    selectedTabName: 'THERAPY',
  },
  {
    displayName: 'Proof of Delivery',
    selectedTabName: 'PROOF_OF_DELIVERY',
  },
];

export const TLNotificationTabs = [
  {
    displayName: 'All',
    selectedTabName: 'ALL',
  },
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
  {
    displayName: 'Proof of Delivery',
    selectedTabName: 'PROOF_OF_DELIVERY',
  },
];

export const SupervisorNotificationTabs = [
  {
    displayName: 'All',
    selectedTabName: 'ALL',
  },
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
  {
    displayName: 'Proof of Delivery',
    selectedTabName: 'PROOF_OF_DELIVERY',
  },
];

export const ProviderNotificationTabs = [
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
];

export const DistributorNotificationTabs = [
  {
    displayName: 'All',
    selectedTabName: 'ALL',
  },
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
  {
    displayName: 'Proof of Delivery',
    selectedTabName: 'PROOF_OF_DELIVERY',
  },
];

export enum NotificationCategory {
  product = 1, // Actionable/Readonly --> These will be included in doctorMobileApp = 9 category
  flexiPap = 2, // Actionable/Readonly --> These will be included in doctorMobileApp = 9 category
  coreServices = 3, // Actionable/Readonly --> These will be included in doctorMobileApp = 9 category
  default = 4 /* Actionable/Readonly --> as we don't have pcsId for this category,
   we will not redirect to summary page --> These will be included in doctorMobileApp = 9 category */,
  enrollment = 5, // Actionable/Readonly --> These will be included in doctorMobileApp = 9 category
  postQuery = 6, // Actionable/Readonly --> These will be included in doctorMobileApp = 9 category

  patientApp = 7 /* Actionable(Enrollement, Enrollement remainder,
     FOC and service requests, rescheduling )/Readonly(cancellation, optional/mandatory docs uploaded by patient) */,
  ops = 8, // Actionable(Lead raised, Foc, concern raised )/Readonly (Exception raised by supervisor, onhold/reject by supervisor)
  doctorMobileApp = 9 /* Actionable (Remaining all cases all actionable) / Readonly (Doctor links with hospital,
     Doctor unlinks with hospital) */,
  proofOfDelivery = 10, // Readonly
  therapy = 11, // Actionable(only for TC user)
}

export enum NotificationCategoryType {
  actionable = '1',
  readonly = '2',
}

export enum NotificationType {
  actionable = 'ACTIONABLE',
  readonly = 'READONLY',
}

export enum NotificationStatus {
  read = 'Read',
  unRead = 'Unread',
}

export enum couponManagementEnum {
  approvalStatus_PendingReview = 2,
  approvalStatus_Approved = 4,
  approvalStatus_Reject = 5,
  couponStatus_Created = 11,
  couponStatus_Ready = 12,
  couponStatus_StartReview = 1,
  couponStatus_Closed = 13,
  couponStatus_Expired = 14,
}

export enum AdminApproveStatus {
  approve = 1,
  reject = 2,
  onhold = 3,
  pending = 4,
  deactivate = 5,
}

export enum vasCategoryStatusTabsEnum {
  info = 'info',
  upcoming = 'upcoming',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum RequestedStatusIdsEnum {
  NoChangeRequest = 6,
  RaiseChangeRequest = 7,
  Approved = 1,
  Reject = 2,
}

export enum vendorIdsEnum {
  Vardhaman = 1,
}

export const SuperAdminNotificationTabs = [
  {
    displayName: 'Ops',
    selectedTabName: 'OPS',
  },
];

export enum AdminPermissions {
  Exception = 1,
  Dashboard_and_Reports = 2,
  Patients = 4
}