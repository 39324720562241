import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpWrapperService } from '../../../app/core/services/http-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class PatientWebService {
  constructor(public httpWrapperService: HttpWrapperService) {}

  loginRequest(loginObj: { fcmID: string; hashCode: string; mobileNumber: any; versionCode: string }): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/authentication/mobile/login', loginObj, false);
  }
  validateOtp(otpObj: { mobileNumber: any; otp: any }): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/authentication/mobile/validateOtp', otpObj, false);
  }
  signupRequest(otpObj: {
    age: any;
    email: any;
    genderName: any;
    careGiverFullName: any;
    careGiverMobileNumber: any;
    careGiverRelationshipId: any;
    sameDispatchAddress: any;
    careGiverAddressAsMine: any;
    address:
      | { addressLine1: any; addressLine2: any; pincode: any; stateId: any; cityId: any }
      | { addressLine1: any; addressLine2: any; pincode: any; stateId: any; cityId: any };
    dispatchAddress:
      | { addressLine1: any; addressLine2: any; pincode: any; stateId: any; cityId: any }
      | { addressLine1: any; addressLine2: any; pincode: any; stateId: any; cityId: any };
    careGiverAddress:
      | { addressLine1: any; addressLine2: any; pincode: any; stateId: any; cityId: any }
      | { addressLine1: any; addressLine2: any; pincode: any; stateId: any; cityId: any };
    termsAndConsentAccepted: any;
    languageId: number;
    dob: any;
    tbtSource: number;
    mobileNumber: any;
    firstName: any;
    middleName: any;
    lastName: any;
  }): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/pcs/api/mobile/signup', otpObj, false);
  }

  getProfileInfo(pcsId: string | null): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', `/pcs/api/mobile/patient/profile?pcsId=${pcsId}`, false);
  }

  getPatientDocuments(pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/pcs/document/mobile/getAllDocs?pcs_id=${pcsId}&typeOfDoc=ENROLLMENT`,
      false
    );
  }
  updateProfileInfo(profileObj: {
    termsAndConsentAccepted?: any;
    languageId?: any;
    hcodetails?: any;
    careGiverFullName?: any;
    careGiverMobileNumber?: any;
    careGiverRelationshipId?: any;
    sameDispatchAddress?: any;
    careGiverAddressAsMine?: any;
    careGiverAddress?: any;
    address?: any;
    dispatchAddress?: any;
    mobileNumber?: any;
    firstName?: any;
    middleName?: any;
    lastName?: any;
    dob?: any;
    age?: any;
    email?: any;
    genderName?: any;
    pcsId?: any;
  }): Observable<any> {
    return this.httpWrapperService.putRequest('enrollmentAPI', '/pcs/api/mobile/patient/profile', profileObj, false);
  }

  savePatientDocument(document: FormData): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/pcs/document/mobile/uploadDoc', document, true);
  }
  
  postReminder(tbtID: string): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', `/pcs/api/mobile/patient/reminder?pcsId=${tbtID}`, '', false);
  }

  getPlannedDates(pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', `/tcPatientEnrollment/getPatientDetails/${pcsId}`, false);
  }

  addPaidInvoice(obj: FormData) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/papScheme/uploadDocument', obj, true);
  }

  addInfusionSummary(obj: FormData) {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/papScheme/updateRecordDate', obj, true);
  }

  deletePatientDoc(docId: any, redact: boolean): Observable<any> {
    return this.httpWrapperService.deleteRequest(
      'enrollmentAPI',
      `/pcs/document/mobile/removeDoc?sl_no=${docId}&isRedact=${redact}`,
      false
    );
  }

  placeOrderConfirmation(obj: any): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/papScheme/v2/placeOrder', obj, true);
  }

  getPaidInvoiceDetails(pcsId: any, pitSeqGen: any, productId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/papScheme/getPaidInvoiceDetails?pitSeqGen=${pitSeqGen}&productId=${productId}&pcsId=${pcsId}`,
      false
    );
  }

  grtRecordInfusionDetials(pcsId: any, pitSeqGen: any, productId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'enrollmentAPI',
      `/papScheme/getRecordInfusion?pitSeqGen=${pitSeqGen}&productId=${productId}&pcsId=${pcsId}`,
      false
    );
  }


  getPatientNotifications(): Observable<any> {
    return this.httpWrapperService.getRequest(
      'notificationAPI',
      `/alert/findMyAlerts`,
      false
    );
  }
  getPatientNotificationsNew(obj: {
    notificationCatagory?: string;
    notificationType: string;
    pageNo: number;
    pageSize: number;
    searchValue: any;
    dateRange?: { fromDate: any; toDate: any };
  }): Observable<any> {
    return this.httpWrapperService.postRequest(
      'notificationAPI',
      `/alert/findPatientAlerts`,
      obj,
      false
    );
  }

 
  patientBookAppointment(postObj: FormData) {
    return this.httpWrapperService.postRequest('vasAPI', `/mobile-vas/book-appointment`, postObj, true);
  }

  serviceInterested(tbtID: string | null, coreServiceId: any): Observable<any> {
    return this.httpWrapperService.postRequest(
      'vasAPI',
      `/mobile-vas/service-interested?coreServiceId=${coreServiceId}&pcsId=${tbtID}`,
      '',
      true
    );
  }

  trackVasRequestStages(patientServiceId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'vasAPI',
      `/mobile-vas/getTrackRequest?patientServiceId=${patientServiceId}`,
      true
    );
  }

  
  getDashboardDetails(pcsId: string | null): Observable<any> {
    return this.httpWrapperService.getRequest(
      'vasAPI',
      `/mobile-vas/dashboard-details?scheduleDate=&pcsId=${pcsId}`,
      false
    );
  }

  getDoctorAssignedServices(pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest(
      'doctorAPI',
      `/vas/doctorAssignedServices?pcsId=${pcsId}`,
      false
    );
  }
  getDoctorRecommendations(pcsId: any): Observable<any> {
    return this.httpWrapperService.getRequest('doctorAPI', `/document/getSharedDocs/${pcsId}`, false);
  }
}
