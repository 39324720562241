import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormControl, FormGroup } from '@angular/forms';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { RoleEnum } from 'src/app/core/enums/app-core.enum';
import { AlertService } from 'src/app/core/services/alert.service';
import { CmsService } from 'src/app/core/services/cms.service';
import { PcsUserManagementService } from 'src/app/core/services/pcs-user-management.service';
import { CryptoService } from 'src/app/core/services/crypto.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { PcsEnrollmentService } from 'src/app/features/services/pcs-enrollment.service';
import publicTermsAndConditionsContent from 'src/app/json/public/terms&conditions.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  userDetails: any;
  accessToken: any;
  errMsg: any;
  currentRole: any;
  roleEnum = RoleEnum;
  @BlockUI()
  blockUI!: NgBlockUI;
  showDashboardFlag = true;
  showExceptionsFlag = true;
  showPatientsFlag = true;
  rfToken!: string;
  rfDetails: any;
  rerender = false;
  menu = false;
  currentUrl!: string;
  patientProfileInfo: any;
  currUrl: any;
  termsLastAcceptedDate: any;
  allLabelsData: any;
  header_cmsContent: any;
  languageForm!: FormGroup;
  languageList: any;
  selectLang: any;
  termsAndConditionsData: any;
  _publicTermsAndConditionsData: any;
  currentMailId!: string;
  currentUserFullName: any;
  patientTbtId: any;
  cmsTenantId: string;
  tokenPayload: any;
  tenantDetails: any;
  vendorForm!: FormGroup;
  vendorList: { vendorId: number; vendorName: string }[];
  vendor: any;
  tenantId: string;

  constructor(
    private userManagementService: PcsUserManagementService,
    private enrollmentService: PcsEnrollmentService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private modalService: NgbModal,
    public cmsService: CmsService,
    private crypto: CryptoService,
    private storageService: StorageService,
    private renderer: Renderer2,
    config: NgbModalConfig
  ) {
    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        this.currUrl = window.location.href;
      }
    });
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'logout';
  }

  ngOnInit(): void {
    this.cmsService.getCMSContent().subscribe((res: any) => {
      this.header_cmsContent = res;
    });

    this.languageForm = new FormGroup({
      languageName: new FormControl(''),
    });
    this.vendorForm = new FormGroup({
      vendorName: new FormControl(''),
    });

    if (localStorage.getItem('token')) {
      this.tokenPayload = jwtDecode(localStorage.getItem('token'));
      this.tenantDetails = this.crypto.aesDecrypt(this.tokenPayload?.tenantDetails);
      this.tenantId = JSON.parse(this.tenantDetails)?.tenantId;
      const themeName = JSON.parse(this.tenantDetails).tenantTheme;
      this.showTheme(themeName);
    }
    this.accessToken = this.storageService.getData('token');
    this.userDetails = jwtDecode(this.accessToken);
    this.currentRole = this.crypto.aesDecrypt(this.userDetails?.roles);
    if (this.userDetails?.FirstName && this.userDetails?.LastName) {
      this.currentUserFullName =
        this.crypto.aesDecrypt(this.userDetails?.FirstName) + ' ' + this.crypto.aesDecrypt(this.userDetails?.LastName);
    }
    if (this.userDetails?.emailId) {
      this.currentMailId = this.crypto.aesDecrypt(this.userDetails?.emailId);
      // Start vendor switch
      if(this.userDetails?.vendorDetails) {
        this.vendorList = JSON.parse(this.crypto.aesDecrypt(this.userDetails?.vendorDetails));
        let currentVendorId = localStorage.getItem('X-vendorID');
        if (currentVendorId) {
          this.vendorForm.controls['vendorName'].patchValue(currentVendorId);
        } else {
          this.vendorForm.controls['vendorName'].patchValue(this.vendorList ? this.vendorList[0]?.vendorId : '1');
        }
      }
      // END vendor switch
    }
    if (this.userDetails?.pcsId) {
      this.patientTbtId = this.crypto.aesDecrypt(this.userDetails?.pcsId);
    }

    this.currUrl = this.router.url;
    this.languageList = JSON.parse(this.crypto.aesDecrypt(this.userDetails?.tenantLanguages));
    this.languageForm.controls['languageName'].patchValue(this.storageService.getData('langCode'));

    if (this.currentRole === this.roleEnum.RocheAdmin) {
      this.getRocheAdminPermissionDetails();
    } else if (this.currentRole === this.roleEnum.Patient) {
      this.getPatientProfileInfo();
    }
  }

  // **** START Get Patient profile Information ****/
  getPatientProfileInfo() {
    const pcsId = this.crypto.aesDecrypt(this.userDetails?.pcsId);
    this.enrollmentService.getProfileInfo(pcsId).subscribe(
      (data: any) => {
        this.patientProfileInfo = data;
      },
      (error: any) => {
        this.alertService.showTBTPatientWarningError({
          title: 'Failed ',
          text: `${error?.data?.messages.toString()}`,
        });
      }
    );
  }
  // **** END Get Patient profile Information ****/

  getTermsConditions() {
    this.blockUI.start('Loading...');
    const cmsTenantId = this.storageService.getData('cmsTenantID');
    const langCode = this.storageService.getData('langCode');
    this.cmsService.getTermsAndConditions(cmsTenantId, langCode, 'terms-and-conditions').subscribe(
      (res: any) => {
        this.blockUI.stop();
        this.termsAndConditionsData = res?.post_content;
      },
      (err: any) => {
        this.blockUI.stop();
        this._publicTermsAndConditionsData = publicTermsAndConditionsContent?.post_content;
      }
    );
  }

  getPendingAlert() {
    setInterval(() => {
      const hours = new Date().getHours();
      const minutes = new Date().getMinutes();
      const seconds = new Date().getSeconds();
      if (hours === 21 && minutes === 2 && seconds === 0) {
        this.alertService.swal
          .fire({
            title: 'Warning!',
            text:
              'Hi ' +
              this.userDetails.FirstName +
              ' ' +
              this.userDetails.LastName +
              ', You have 3 pending tasks. Please complete it ASAP',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            reverseButtons: false,
          })
          .then((_result: any) => {});
      }
    }, 1000);
  }
  // Dash Board
  openDashboard(): void {
    if (this.currentRole === this.roleEnum.Field_Counsellor) {
      this.alertService.redirect('fc/dashboard');
    } else if (
      this.currentRole === this.roleEnum.Telle_Caller ||
      this.currentRole === this.roleEnum.Team_Lead ||
      this.currentRole === this.roleEnum.Supervisor
    ) {
      this.alertService.redirect('ops/dashboard');
    } else if (this.currentRole === this.roleEnum.RocheAdmin || this.currentRole === this.roleEnum.SuperAdmin) {
      this.alertService.redirect('admin/dashboard');
    }
  }

  getRocheAdminPermissionDetails(): void {
    this.blockUI.start('Loading...');
    this.showExceptionsFlag = false;
    this.showDashboardFlag = false;
    this.showPatientsFlag = false;
    this.userManagementService.getPermissionDetails().subscribe(
      (response: any) => {
        this.blockUI.stop();
        response?.forEach((element: any) => {
          if (element?.permissionName?.toUpperCase() === 'Exception'.toUpperCase()) {
            this.showExceptionsFlag = true;
          } else if (element?.permissionName?.toUpperCase() === 'Dashboard and Reports'.toUpperCase()) {
            this.showDashboardFlag = true;
          } else if (element?.permissionName?.toUpperCase() === 'Patients'.toUpperCase()) {
            this.showPatientsFlag = true;
          }
        });
      },
      (err: any) => {
        this.blockUI.stop();
      }
    );
  }

  navigateToUsers(listName: string) {
    if (listName.toUpperCase() === 'rolesAndPermissions'.toUpperCase()) {
      this.alertService.redirect('/admin/roles-permissions');
    } else if (listName.toUpperCase() === 'demoUsers'.toUpperCase()) {
      this.alertService.redirect('/admin/demo-users');
    } else if (listName.toUpperCase() === 'caRolesAndPermissions'.toUpperCase()) {
      this.alertService.redirect('admin/ca-roles-permissions');
    }
  }
  logoutClickHandler() {
    this.modalService.dismissAll();
    localStorage.clear();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  portalLogout() {
    localStorage.clear();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  logoutModal(logout: any) {
    this.modalService.open(logout, {
      size: 'sm',
      centered: true,
    });
  }

  closeModel() {
    this.modalService.dismissAll();
  }

  menuIcon() {
    this.menu = !this.menu;
  }

  changePasswordClickHandler() {
    this.router.navigate(['/change-password']);
  }

  navigateToVasServiceProviderList(listName: string) {
    if (listName.toUpperCase() === 'service'.toUpperCase()) {
      this.alertService.redirect('/admin/vas-service-list');
    } else if (listName.toUpperCase() === 'provider'.toUpperCase()) {
      this.alertService.redirect('/admin/vas-provider-list');
    } else if (listName.toUpperCase() === 'coupon_management'.toUpperCase()) {
      this.alertService.redirect('/admin/coupon-management');
    }
  }

  openTermsPopup(popup: any) {
    let date = localStorage.getItem('termsAcceptedOn');
    this.termsLastAcceptedDate = moment.utc(date).local();
    this.getTermsConditions();
    this.modalService.open(popup, {
      size: 'lg',
      centered: true,
    });
  }

  selectedLanguage(event: any) {
    this.selectLang = event.target.value;
    localStorage.setItem('langCode', this.selectLang);
    this.cmsTenantId = localStorage.getItem('cmsTenantID');
    this.blockUI.start('Loading...');
    let baseCMSAPI = 'cmsAPI';
    let offlineCMSAPI = 'cmsOfflineAPI';
    this.cmsService.getCmsContentData(baseCMSAPI,this.cmsTenantId, this.selectLang).subscribe((res: any) => {
      this.cmsService.setCmsData(res);
      this.blockUI.stop();
    },(error: any) => {
      this.cmsService.getCmsContentData(offlineCMSAPI,this.cmsTenantId, this.selectLang).subscribe((res: any) => {
       this.cmsService.setCmsData(res);
       this.blockUI.stop();
     })
    });
    if (localStorage.getItem('token')) {
      this.languageForm.controls['languageName'].patchValue(this.selectLang);
    }
    // this.cmsService.notifyLanguageChange(true);

  }

  selectedVendorSwitch(event: any) {
    this.vendor = event.target.value;
    this.storageService.storeData('X-vendorID', this.vendor);
    if (localStorage.getItem('token')) {
      this.vendorForm.controls['vendorId'].patchValue(this.vendor);
      window.location.reload();
    }
  }

  showTheme(event: any) {
    this.renderer.setAttribute(document.body, 'data-bs-theme', event);
  }
}
