<div class="container-fluid tbt-container feedback-page-wrapper">
  <div class="page-heading-wrapper">
    <div class="card public-feedback-wrapper">
      <div class="card-body">
        <div class="heading-wrapper-feedback">
          <h1 class="title-feedback">{{ feedbackCmsData?.pagetitles?.feedback }}</h1>
        </div>

        <img [src]="feedbackCmsData?.imagesandicons?.customer_feedback" class="img-fluid customer-feedback" alt="feedback-img" />
        <p class="feedback-subtitle">{{ feedbackCmsData?.pageheadingsandparagraphs?.yourExperience }}</p>

        <div class="mobile-ratings-wrapper">
          <form autocomplete="off" [formGroup]="feedbackForm">
            <div class="single-ratings">
              <div class="card mt-3" *ngFor="let question of questionsList">
                <div class="card-body">
                  <div class="rating-section">
                    <div class="rating-wrapper">
                      <p class="mobile-rating-styles">{{ question?.questionarieDescription }}</p>
                      <ngb-rating [max]="5" class="rating-stars"
                        formControlName="{{ question?.questionarieId }}"></ngb-rating>
                    </div>
                  </div>
                </div>
              </div>
              <p class="share-view">
                {{ feedbackCmsData?.pageheadingsandparagraphs?.Shareyourreview }}
              </p>

              <div class="rating-review-comment">
                <div class="rating-card">
                  <textarea id="premarks" class="form-control feedback-review-area scrollable-form-wrapper"
                    placeholder="Enter review here" maxlength="1000" formControlName="review"></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>

        <button type="submit" class="btn tbt-primary-btn feedback-submit" [disabled]="!feedbackForm.valid"
          (click)="onSubmit()">
          {{ feedbackCmsData?.buttons?.submit }}
        </button>
      </div>
    </div>
  </div>
</div>