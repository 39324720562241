import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppUtilityService {
  ConstDate: any = [];
  constructor(private httpClient: HttpClient) {}

  /**
   * // -- This function will check invalid data
   * @param data -> Data object for validation checks
   */

  public isInvalidData(data: any): any {
    if (typeof data === 'string') {
      return data === null || data === '' || data === undefined ? true : false;
    } else if (typeof data === 'number') {
      return data === 0 ? true : false;
    } else if (typeof data === 'object') {
      return data && Object.keys(data).length === 0 ? true : false;
    }
  }

  /**
   * // -- This function will generate random number from given 'range'.
   * @param range -> Any integer value upto which some random value is being selected.
   */

  public getRandomNumberFromRange(range: number): number {
    return Math.floor(Math.random() * Math.floor(range));
  }

  /**
   * // -- This function will help to get single matching object or null
   * @param value -> Value which will be used for comparison
   * @param key -> Object key
   * @param data -> Array|List
   */
  public getSingleObjectFromList(value: any, key: any, data: any[]): any {
    const selectedObject = data.find((obj) => {
      return obj[key] === value;
    });
    return selectedObject ? selectedObject : null;
  }

  public changeDateFormat(date: any): any {
    const d = new Date(date);
    const startDate = moment(d, 'DD.MM.YYYY');
    const endDate = moment(new Date(), 'DD.MM.YYYY');
    if (endDate.diff(startDate, 'hours') > 24) {
      return moment(date).format('DD/MM/yyyy hh:mm:ss a');
    } else {
      return moment(date).startOf('minutes').fromNow();
    }
  }

  changeObj(set: any, fieldName: string) {
    for (const property in set) {
      if (set[property][fieldName]) {
        set[property].name = set[property][fieldName];
        delete set[property][fieldName];
      }
    }
    return set;
  }

  async getBase64ImageFromUrl(imageUrl: RequestInfo | URL) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  changeObjKey(roleOptions: any) {
    const newArray = roleOptions.map((item: any) => {
      return { roleId: item.roleId, name: item.roleName };
    });
    roleOptions = newArray;
    return roleOptions;
  }
}
