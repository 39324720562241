// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
       //API SERVICES NEW END POINT"S
       userManagementAPI: 'https://qa-services.patientcaresolution.com/api/pcs-user-mgmt',
       tenantManagementAPI: 'https://qa-services.patientcaresolution.com/api/pcs-tenant-mgmt',
       enrollmentAPI: 'https://qa-services.patientcaresolution.com/api/pcs-enrollment',
       vasAPI: 'https://qa-services.patientcaresolution.com/api/pcs-vas',
       vialAPI: 'https://qa-services.patientcaresolution.com/api/pcs-vial',
       papAPI: 'https://qa-services.patientcaresolution.com/api/pcs-pap',
       feedbackAPI: 'https://qa-services.patientcaresolution.com/api/pcs-feedback',
       documentAPI: 'https://qa-services.patientcaresolution.com/api/pcs-document',
       doctorAPI: 'https://qa-services.patientcaresolution.com/api/pcs-doctor',
       reportsAPI: 'https://qa-services.patientcaresolution.com/api/pcs-report',
       notificationAPI: 'https://qa-services.patientcaresolution.com/api/pcs-notification',
       cmsAPI: 'https://qa-cms.patientcaresolution.com',
       loginKey: 'TBTBLUT##07$20222',
       ivKey: '1234567890123456',
       debugging: true,
  },
};

/*
 * For easier debugging  mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
