import { Injectable } from '@angular/core';
import { HttpWrapperService } from 'src/app/core/services/http-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class PcsFeedbackService {
  constructor(public httpWrapperService: HttpWrapperService) {}

  // ***START SERVICES METHOD'S HERE ***
  getEnrollmentFeedbackDetails(pcsId: any) {
    return this.httpWrapperService.getRequest(
      'feedbackAPI',
      `/patient-enrollment/getFeedback-details?pcsId=${pcsId}`,
      false
    );
  }

  getFocFeedbackDetails(pcsId: any, orderId: any) {
    return this.httpWrapperService.getRequest(
      'feedbackAPI',
      `/patient-foc/getFocFeedback-details?orderId=${orderId}&pcsId=${pcsId}`,
      false
    );
  }

  getVasFeedbackDetails(pcsId: any, patientServiceId: any) {
    return this.httpWrapperService.getRequest(
      'feedbackAPI',
      `/patient-vas/vasFeedback-details?patientServiceId=${patientServiceId}&pcsId=${pcsId}`,
      false
    );
  }

  getFeedbackQuestionsBasedOnCategory(catId: string | number | undefined) {
    return this.httpWrapperService.getRequest(
      'feedbackAPI',
      '/feedbackquestionarie/getFeedbackQuestionariesByFeedbackCategoryId/' + catId,
      false
    );
  }

  postFeedbackQuestionsForPatientService(
    comments: any,
    patientServiceId: any,
    skip: string | boolean,
    pcsId: string | null,
    requestObject: any
  ) {
    return this.httpWrapperService.postRequest(
      'feedbackAPI',
      `/patient-vas/feedback-details?comments=${comments}&patientServiceId=${patientServiceId}&skip=${skip}&pcsId=${pcsId}`,
      requestObject,
      false
    );
  }

  postFeedbackQuestionsForPatientEnrollment(comments: any, skip: any, pcsId: string, requestObject: any) {
    return this.httpWrapperService.postRequest(
      'feedbackAPI',
      `/patient-enrollment/feedback-details?comments=${comments}&skip=${skip}&pcsId=${pcsId}`,
      requestObject,
      false
    );
  }

  postFeedbackQuestionsForPatientFoc(
    comments: any,
    orderid: any,
    skip: string | boolean,
    pcsId: string,
    requestObject: any
  ) {
    return this.httpWrapperService.postRequest(
      'feedbackAPI',
      `/patient-foc/savefeedback-details?comments=${comments}&orderid=${orderid}&skip=${skip}&pcsId=${pcsId}`,
      requestObject,
      false
    );
  }
}
