export const AppCoreConstants = {};

export const SignupFormBypassToken = {
  signupToken:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJycUovb25PYWJjMFdXNWJSa0MraGt6Z1E3clBqNTdRQ2VXNTBqMXB0dGQwPSIsIkZpcnN0TmFtZSI6IjV1MDk5L2VwaEZndkNiQ3pTeXprRnc9PSIsInJvbGVzIjoibjBZZURIREhBYVEzaFljUEJzWjJvdz09IiwiaXNzIjoiL2xvZ2luIiwiZW1haWxJZCI6InJxSi9vbk9hYmMwV1c1YlJrQytoa3pnUTdyUGo1N1FDZVc1MGoxcHR0ZDA9Iiwic291cmNlIjoiMSIsInVzZXJJZCI6ImI2SXB4Ujc4SDBySTNldzlidmRsaUE9PSIsImN1cnJlbnRSb2xlSWQiOiIyIiwidGVuYW50TGFuZ3VhZ2VzIjoiZ2xzNkNhWEpaaWVzWHFjSmxFQTN2d01DTE1oQ3dsZ3h0bzdsVThoeVJqSVVqazBRWm9zSTE4eHdqSE5zeHBTdFZKNlAyOHI3T05UVk9Bc0FjWmZYYlFJLys4ZjB5d3lwd21BQWlqOGx2U2Z1aEE1NTBEYkdKdmZrNE5GdGJzSjVSMHptSk93Zi9GcEFXZC94QVNCRDQ1emNhM3JtVXVTYmV5TGhVN3BrQ2crbGROQlRnZkYwV1c5TFRvWmU5ck8rZWs5ZDQ3a1dnUzgvL1ZnbitTVHBmUT09IiwidGVuYW50RGV0YWlscyI6IkpoUzY3dDloRktiWldSZ2c4VWpqQ2hDMEhJdVpjS0czZnU4WDY4OWtWUkpDTlFLRTlrTkkwR1RkRTZnU2paWjByMnFrWkFXZGMvV2NPdWd2Si84dzdVbTRNS3V5QXFUR0REVGExalZwbS9BQmFNNk50d1FqUEpVRy9UUzY0THNnIiwicm9sZU5hbWUiOiJuMFllREhESEFhUTNoWWNQQnNaMm93PT0iLCJkZXRhaWxzIjoiOFdrR2NjdEN0S3UwcXAyd0FoWVduM1F6K0VDcEsxZytvcWZtZi90VjdGa09oYmN3eHNhSkw1YWpwUC85dzd5UE9nRTBKcHduTHgxZTVlcUpqbnpvMXc9PSIsIkxhc3ROYW1lIjoiVVJ5b0ZsYlRmWjB3TVc3cFlEZ1dEdz09IiwiZXhwIjozNTg5NTQwMTUwfQ.QLletIQlvQJwoz0mdtChoCFUtQGeomKvDUcP6i7MwU8',
};

export const SignupFormTaiwanBypassToken = {
  signupToken:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJEU2tIRHRwMXJ1NFppdXhJKzN6VG5nOGM5bWY4VnJoUjZ5VU5jZ3FFT2pBVmNFTTNxR3VKdE04czdhd2Y1bjJ2IiwiRmlyc3ROYW1lIjoiRFNrSER0cDFydTRaaXV4SSszelRucE5aazhxWmIvZkNoTnJmT25XN3ZBMD0iLCJyb2xlcyI6InA5NEpSak41NmljWWpiTjdYcDQwUUxDVmhmOGI5c2hyWHdFMlJnczRoS289IiwiaXNzIjoiL2xvZ2luIiwiZW1haWxJZCI6IkRTa0hEdHAxcnU0Wml1eEkrM3pUbmc4YzltZjhWcmhSNnlVTmNncUVPakFWY0VNM3FHdUp0TThzN2F3ZjVuMnYiLCJzb3VyY2UiOjEsInVzZXJJZCI6ImRYdjVlUXBvR0cvaWFSaytMbk1XeWc9PSIsImN1cnJlbnRSb2xlSWQiOjIsInRlbmFudExhbmd1YWdlcyI6IkhqU2RPVjJJcTZkM3NaZUVYK1p3Z0ZjYzh6dWZLVW9UTS9XWkoycE9teTFsaWtJM0o2ZkJEOWp0OGFRYWRaU0Yra1NOc2EycWhjaWR0b3EzblUzQWhQYjJUcTZmSG9icjVrZnZFdzZvR3JLa3l5ZGUrN3pUWWkvTUtUYWpyYkJZb1M1NksxZW5xS0NoMlRWUnVVZlhPN3dFQVRvei95R1psNytIMUlNMWNrVmVSVzRpbjVqdUtzV3pIVHZQVFJmZCIsInRlbmFudERldGFpbHMiOiI4bkN1cVFVamNpVitBd3hmMitsb01CUHBkYVpOM0tUbWNUQTczZTg1Z2VaeHlJTkUwQ1pXRVVaS1J3Zm1lM0RkblNNTzFqS2Y0c2Nsb2dXRjZjc1M1S1NETWRLU3pBSGQyZjNSYk0xMUMxU0tnNDhha3hiNDBUcXZIaEdXVHBJYk1oQzJwUEhnaTEvWjlyaUROZ3FHaDZEOG1RWEFuY3NIOWxud1hGYURPank2ZlhHYnFSb3V5UVJ2ZG0yUEFVMjkiLCJyb2xlTmFtZSI6InA5NEpSak41NmljWWpiTjdYcDQwUUxDVmhmOGI5c2hyWHdFMlJnczRoS289IiwiZGV0YWlscyI6IjhXa0djY3RDdEt1MHFwMndBaFlXbjNReitFQ3BLMWcrb3FmbWYvdFY3RmtPaGJjd3hzYUpMNWFqcFAvOXc3eVBPZ0UwSnB3bkx4MWU1ZXFKam56bzF3PT0iLCJMYXN0TmFtZSI6IkRiUnllc1pDUFFPQmlyMmNZNWZDUWc9PSIsImV4cCI6MzU5MjIyNTg5Nn0.q7-vKaleDT3HsK0s4qzaJnU870wliEBFKIBQ6b9zWKY',
};

export const onBoardingToken = {
  token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyb2NoZXRidGdsb2JhbEBtb3VyaXRlY2guY29tIiwiVXNlciBJZCI6IkVNUDgwMzQiLCJFbWFpbCI6InJvY2hldGJ0Z2xvYmFsQG1vdXJpdGVjaC5jb20iLCJpc3MiOiJyb2NoZXRidGdsb2JhbEBtb3VyaXRlY2guY29tIiwiRmlyc3QgTmFtZSI6IlJvY2hlIiwiZXhwIjozNTg4NjU5NTE0LCJQYXNzd29yZCI6IjhXa0djY3RDdEt1MHFwMndBaFlXbjNReitFQ3BLMWcrb3FmbWYvdFY3RmtPaGJjd3hzYUpMNWFqcFAvOXc3eVBPZ0UwSnB3bkx4MWU1ZXFKam56bzF3PT0ifQ.o4OHzFz9r_Uhx7yWQHk66TGD7jTIn3Q_bw20Ams46Y4'
};
