// Response Code References : https://www.restapitutorial.com/httpstatuscodes.html
export class ResponseHandler {
  private customisedResponse = {
    statusCode: 200,
    data: [],
  };
  // - 1XX Informational
  public responseCode_100(res: any) {
    return this.customisedResponse;
  }

  public responseCode_101(res: any) {
    return this.customisedResponse;
  }

  public responseCode_102(res: any) {
    return this.customisedResponse;
  }

  // - 2XX Success
  public responseCode_200(res: any) {
    return this.customisedResponse;
  }

  public responseCode_201(res: any) {
    return this.customisedResponse;
  }

  public responseCode_202(res: any) {
    return this.customisedResponse;
  }

  public responseCode_203(res: any) {
    return this.customisedResponse;
  }
  public responseCode_204(res: any) {
    return this.customisedResponse;
  }

  public responseCode_205(res: any) {
    return this.customisedResponse;
  }

  public responseCode_206(res: any) {
    return this.customisedResponse;
  }

  public responseCode_207(res: any) {
    return this.customisedResponse;
  }

  public responseCode_208(res: any) {
    return this.customisedResponse;
  }

  public responseCode_226(res: any) {
    return this.customisedResponse;
  }

  // - 3XX Redirection
  public responseCode_300(res: any) {
    return this.customisedResponse;
  }

  public responseCode_301(res: any) {
    return this.customisedResponse;
  }

  public responseCode_302(res: any) {
    return this.customisedResponse;
  }

  public responseCode_303(res: any) {
    return this.customisedResponse;
  }

  public responseCode_304(res: any) {
    return this.customisedResponse;
  }

  public responseCode_305(res: any) {
    return this.customisedResponse;
  }

  public responseCode_306(res: any) {
    return this.customisedResponse;
  }

  public responseCode_307(res: any) {
    return this.customisedResponse;
  }

  public responseCode_308(res: any) {
    return this.customisedResponse;
  }

  // - 4XX Client Error
  public responseCode_400(res: any) {
    return this.customisedResponse;
  }

  public responseCode_401(res: any) {
    return this.customisedResponse;
  }

  public responseCode_402(res: any) {
    return this.customisedResponse;
  }

  public responseCode_403(res: any) {
    return this.customisedResponse;
  }

  public responseCode_404(res: any) {
    return this.customisedResponse;
  }

  public responseCode_405(res: any) {
    return this.customisedResponse;
  }

  public responseCode_406(res: any) {
    return this.customisedResponse;
  }

  public responseCode_407(res: any) {
    return this.customisedResponse;
  }

  public responseCode_408(res: any) {
    return this.customisedResponse;
  }

  public responseCode_409(res: any) {
    return this.customisedResponse;
  }

  public responseCode_410(res: any) {
    return this.customisedResponse;
  }

  public responseCode_411(res: any) {
    return this.customisedResponse;
  }

  public responseCode_412(res: any) {
    return this.customisedResponse;
  }

  public responseCode_413(res: any) {
    return this.customisedResponse;
  }

  public responseCode_414(res: any) {
    return this.customisedResponse;
  }

  public responseCode_415(res: any) {
    return this.customisedResponse;
  }

  public responseCode_416(res: any) {
    return this.customisedResponse;
  }

  public responseCode_417(res: any) {
    return this.customisedResponse;
  }

  public responseCode_418(res: any) {
    return this.customisedResponse;
  }

  public responseCode_420(res: any) {
    return this.customisedResponse;
  }

  public responseCode_422(res: any) {
    return this.customisedResponse;
  }

  public responseCode_423(res: any) {
    return this.customisedResponse;
  }

  public responseCode_424(res: any) {
    return this.customisedResponse;
  }

  public responseCode_425(res: any) {
    return this.customisedResponse;
  }

  public responseCode_426(res: any) {
    return this.customisedResponse;
  }

  public responseCode_428(res: any) {
    return this.customisedResponse;
  }

  public responseCode_429(res: any) {
    return this.customisedResponse;
  }

  public responseCode_431(res: any) {
    return this.customisedResponse;
  }

  public responseCode_444(res: any) {
    return this.customisedResponse;
  }

  public responseCode_449(res: any) {
    return this.customisedResponse;
  }

  public responseCode_450(res: any) {
    return this.customisedResponse;
  }

  public responseCode_451(res: any) {
    return this.customisedResponse;
  }

  public responseCode_499(res: any) {
    return this.customisedResponse;
  }

  // - 5XX Server Error
  public responseCode_500(res: any) {
    return this.customisedResponse;
  }

  public responseCode_501(res: any) {
    return this.customisedResponse;
  }
  public responseCode_502(res: any) {
    return this.customisedResponse;
  }
  public responseCode_503(res: any) {
    return this.customisedResponse;
  }
  public responseCode_504(res: any) {
    return this.customisedResponse;
  }
  public responseCode_505(res: any) {
    return this.customisedResponse;
  }
  public responseCode_506(res: any) {
    return this.customisedResponse;
  }
  public responseCode_507(res: any) {
    return this.customisedResponse;
  }
  public responseCode_508(res: any) {
    return this.customisedResponse;
  }
  public responseCode_509(res: any) {
    return this.customisedResponse;
  }
  public responseCode_510(res: any) {
    return this.customisedResponse;
  }
  public responseCode_511(res: any) {
    return this.customisedResponse;
  }
  public responseCode_598(res: any) {
    return this.customisedResponse;
  }
  public responseCode_599(res: any) {
    return this.customisedResponse;
  }
}
