<div class="change-password-page-wrapper login-bg-wrapper login-outer-wrapper login-page-wrapper">
  <div class="w-100 p-0 h-100 tbt-bg-container position-relative">
    <div class="row align-items-center h-100 g-0">
      <div class="col-lg-6 d-none d-lg-block d-sm-none bg-color-left">
        <div class="login-bg-inner-wrapper d-flex align-items-center justify-content-center">
          <img [src]="changePasswordCMSData?.images?.pcsLogo" class="img-fluid login-logo" alt="PCS Program"
            width="300" height="350" />
        </div>
      </div>

      <div class="col-lg-6 col-sm-12 login-bg"
        style="background-image: url({{ changePasswordCMSData?.images?.PCSLoginbg}})">
        <div class="login-wrapper h-100" [formGroup]="changePassword">
          <div class="container-fluid h-100">
            <div class="row h-100">
              <div class="col-12 align-self-center login-inner-wrapper-col">
                <div class="login-inner-wrapper change-password">
                  <div class="tbt-brand-wrapper">
                    <span class="d-inline-block tbt-brand-logo">
                      <img [src]="changePasswordCMSData?.images?.landing_logo" class="img-fluid" alt="PCS logo"
                        width="150" height="150">
                    </span>
                  </div>
                  <div class="card border-0 tbt-rounded-xl pcs-card--shadow">
                    <div class="tbt-card-body--change-password card-body">
                      <div class="heading-wrapper">
                        <h3 class="heading">
                          {{changePasswordCMSData?.titles?.changePasswordTitle }}
                        </h3>
                        <p>
                          {{changePasswordCMSData?.subtitles?.changePassword_subtitle }}
                        </p>
                      </div>
                      <form class="login-form" [formGroup]="changePassword">
                        <div class="form-group prepend-group required-asterisk">
                          <label class="custom-label" for="currentpassword">
                            {{changePasswordCMSData?.labels?.currentPassword }}
                          </label>
                          <div class="input-group new-password-input-group tbt-input-group">
                            <input [type]="currentPasswordVisibility ? 'text' : 'password'" class="form-control"
                              formControlName="currentPassword" list="datalistOptions"
                              placeholder="{{changePasswordCMSData?.placeholders?.currentPassword }}"
                              aria-label="Search" aria-describedby="basic-addon2" />
                            <span class="input-group-text" id="basic-addon2">
                              <img [src]="
                                  currentPasswordVisibility
                                    ? 'changePasswordCMSData?images?.password_visible'
                                    : changePasswordCMSData?.images?.password_not_visible
                                " alt="" (click)="toggleFieldTextTypeCurrentPass()" class="img-fluid" />
                            </span>
                          </div>
                          <p class="password-error-message">
                            {{changePasswordCMSData?.validation?.currentPassword }}
                          </p>
                        </div>
                        <div class="form-group prepend-group required-asterisk">
                          <label class="custom-label" for="role">
                            {{changePasswordCMSData?.labels?.newPassword }}
                          </label>
                          <div class="input-group new-password-input-group tbt-input-group">
                            <input [type]="newPasswordVisibility ? 'text' : 'password'" class="form-control"
                              formControlName="newPassword" list="datalistOptions"
                              placeholder="{{ changePasswordCMSData?.placeholders?.newPassword }}" aria-label="Search"
                              aria-describedby="basic-addon2" (keyup)="passwordLength()" />
                            <span class="input-group-text" id="basic-addon2">
                              <img [src]="
                                  newPasswordVisibility                                 
                                    ? 'changePasswordCMSData?.images?.password_visible'
                                    : 'changePasswordCMSData?.images?.password_not_visible'
                                " alt="" (click)="toggleFieldTextTypeNewPass()" class="img-fluid" />
                            </span>
                          </div>
                          <p [ngClass]="
                              passLength && changePassword.value.newPassword !== ''
                                ? 'password-success-message'
                                : 'password-error-message'
                            ">
                            {{changePasswordCMSData?.validation?.newPassword }}
                          </p>
                        </div>
                        <div class="form-group prepend-group required-asterisk">
                          <label class="custom-label" for="role">
                            {{changePasswordCMSData?.labels?.cnfirmNewPassword }}
                          </label>
                          <div class="input-group new-password-input-group tbt-input-group">
                            <input [type]="confirmPasswordVisibility ? 'text' : 'password'" class="form-control"
                              formControlName="confirmPassword" list="datalistOptions"
                              placeholder="{{changePasswordCMSData?.placeholders?.againNewPassword }}"
                              aria-label="Search" aria-describedby="basic-addon2" (keyup)="keyFunc()" />
                            <span class="input-group-text" id="basic-addon2">
                              <img [src]="
                                  confirmPasswordVisibility
                                    ? 'changePasswordCMSData?.images?.password_visible'
                                    : 'changePasswordCMSData?.images?.password_not_visible'
                                " alt="" (click)="toggleFieldTextTypeConfirmPass()" class="img-fluid" />
                            </span>
                          </div>
                          <p [ngClass]="
                              passwordMatch && changePassword.value.confirmPassword !== ''
                                ? 'password-success-message'
                                : 'password-error-message'
                            ">
                            {{
                            passwordMatch && changePassword.value.confirmPassword !== ''
                            ? 'Password match'
                            : 'Passwords must match'
                            }}
                          </p>
                        </div>
                        <div class="text-center mt-4">
                          <button type="submit" class="btn btn-login tbt-primary-btn" (click)="changeNewPassword()"
                            [disabled]="!enableButton()" [ngClass]="{
                              'invalid-btn': changePassword.invalid
                            }">
                            {{changePasswordCMSData?.buttons?.changePassword }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>