import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AppConfig } from '../configs/app.configs';
import { ResponseHandler } from './response-handler';
import { onBoardingToken } from '../constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class HttpWrapperService {
  private methodName = '';
  private classInstance: any = '';
  reqUrl = '';
  token = '';
  cmsToken: any;
  x_tenantId: any;
  x_vendorId: any;
  onBoardingToken = onBoardingToken;

  constructor(public httpClient: HttpClient, private config: AppConfig) {}

  /**
   * -- This function is to identify request type and to get exact API source URL
   * @param apiType -> e.g. cmsAPI
   */

  public getAPISourceUrl(apiType: string) {
    return this.config.get(apiType);
  }

  /**
   * Set Headers will help to generate headers based on request type and multipart value.
   * @param requestType -> This parameter will identify request type and will help to create related request headers.
   * @param multipart -> This parameter will be helpful for multipart data handling like file upload
   */

  private setHeaders(options: any): HttpHeaders {
    this.token = localStorage.getItem('token') || '';
    this.x_tenantId = localStorage.getItem('X-tenantID');
    this.x_vendorId = localStorage.getItem('X-vendorID');
    const headersConfig: { [key: string]: string } = {
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'If-Modified-Since': '0',
      'X-TenantID': this.x_tenantId,
    };
    
    if (this.x_vendorId) {
      headersConfig['X-VendorID'] = this.x_vendorId;
    }

    if (options.multipartFormData) {
      headersConfig['enctype'] = 'multipart/form-data';
    }
    if (options.zipOption) {
      headersConfig['Accept'] = 'application/zip';
    }
    if (options.noAuth) {
      headersConfig['No-Auth'] = 'True';
    }
    if (options.GET_BLOB) {
      headersConfig['Content-Type'] = 'application/json';
      headersConfig['responseType'] = 'blob' as 'json';
    }
    if (options.GET_BLOB_FILE) {
      headersConfig['Content-Type'] = 'plain/txt';
      headersConfig['Content-Disposition'] = 'attachment';
    }
    return new HttpHeaders(headersConfig);
  }

  private setCMSHeaders(options: any): HttpHeaders {
    this.token = localStorage.getItem('token') || '';
    const headersConfig: { [key: string]: string } = {
      Accept: 'application/json',
      'X-Api-Key': 'Gp08899Eu54iqwX0XzOtHavZ6u3NZIL887buS6kJ',
    };
    if (this.token) {
      headersConfig['Authorization'] = `${this.token}`;
    }
    return new HttpHeaders(headersConfig);
  }

  private setOnBoardingHeaders(options: any): HttpHeaders {
    const headersConfig: { [key: string]: string } = {
      Accept: 'application/json',
      // Authorization: `Bearer ${this.onBoardingToken?.token}`
    };
    return new HttpHeaders(headersConfig);
  }

  /**
   *  -- GET Method requests
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public getRequest(apiType: string, url: string, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setHeaders({
        multipartFormData: isMultiPart,
      }),
    };
    return this.httpClient.get(this.reqUrl, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  // ***START Get CMS request ****
  public getCMSRequest(apiType: string, url: string, isCMS: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setCMSHeaders({}),
    };
    return this.httpClient.get(this.reqUrl, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  public getOTRequest(apiType: string, url: string, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setOnBoardingHeaders({})
    };
    return this.httpClient.get(this.reqUrl, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  public postOTRequest(apiType: string, url: string, body: any, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setOnBoardingHeaders({})
    };
    return this.httpClient.post(this.reqUrl, body, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }


  

  public getBlobRequest(apiType: string, url: string, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setHeaders({
        multipartFormData: isMultiPart,
        GET_BLOB: true,
      }),
      observe: 'response',
    };
    return this.httpClient.get(this.reqUrl, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * -- GET Method requests (File Download)
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public getDownloadFileRequest(apiType: string, url: string): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setHeaders({
        GET_BLOB_FILE: true,
      }),
    };
    return this.httpClient.get(this.reqUrl, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   *  -- PUT Method requests
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public putRequest(apiType: string, url: string, body: any, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setHeaders({
        multipartFormData: isMultiPart,
      }),
    };
    return this.httpClient.put(this.reqUrl, body, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * -- PUT Method requests (Blob Data)
   * @param url -> request URL
   * @param body -> request object
   */

  public putBlobRequest(apiType: string, url: string, body: any): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headers = this.setHeaders(false);
    return this.httpClient.put(this.reqUrl, body, { headers }).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('status')) {
          return this.handleResponseData(res);
        }
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * -- POST Method requests
   * @param url -> request URL
   * @param body -> request object
   */

  public postRequest(apiType: string, url: string, body: any, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setHeaders({
        multipartFormData: isMultiPart,
      }),
    };
    return this.httpClient.post(this.reqUrl, body, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  public postTextRequest(apiType: string, url: string, body: any, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    return this.httpClient
      .post(this.reqUrl, body, {
        responseType: 'text',
        headers: new HttpHeaders({
          'X-TenantID': this.x_tenantId,
        }),
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

  /**
   * -- POST Method requests (Blob Data)
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public postBlobRequest(apiType: string, url: string, body: any): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headers = this.setHeaders(false);
    return this.httpClient.post(this.reqUrl, body, { headers }).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('status')) {
          return this.handleResponseData(res);
        }
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * -- POST Method requests (File Upload)
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public postFileUpload(apiType: string, url: string, body: any): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headers = this.setHeaders(false);
    return this.httpClient.post(this.reqUrl, body, { headers }).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('status')) {
          return this.handleResponseData(res);
        }
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * -- PATCH Method requests
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public patchRequest(apiType: string, url: string, body: any): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headers = this.setHeaders(false);
    return this.httpClient.patch(this.reqUrl, body, { headers }).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('status')) {
          return this.handleResponseData(res);
        }
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * - DELETE Method requests
   * @param url -> request URL
   * @param body -> (optional) request object
   */

  public deleteRequest(apiType: string, url: string, isMultiPart: boolean): any {
    this.reqUrl = this.getAPISourceUrl(apiType);
    this.reqUrl += url;
    const headerObj: Object = {
      headers: this.setHeaders({
        multipartFormData: isMultiPart,
      }),
    };
    return this.httpClient.delete(this.reqUrl, headerObj).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.errorHandler)
    );
  }

  /**
   * Following methods will help to handle API responses. Response data handling
   * @param res -> received API response object with all response information including status code and body
   */

  public handleResponseData(res: any): Promise<any> {
    this.methodName = 'responseCode_' + res.status;
    this.classInstance = new ResponseHandler();
    const responsePromise = this.classInstance[this.methodName](...res);

    return Promise.resolve(responsePromise);
  }

  /**
   * Following methods will help to handle API errors. Response error handling
   * @param res -> received API response object with all response information including status code and body
   */

  public handleResponseError(res: any): Promise<any> {
    this.methodName = 'responseCode_' + res.status;
    this.classInstance = new ResponseHandler();
    const responsePromise = this.classInstance[this.methodName](...res);

    return Promise.reject(responsePromise);
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else if (error?.error?.data && error?.status == 403) {
      errorMessage = error?.error;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
