import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  /** scroll to top -- used in html(on change of routes)*/
  onActivate(_event: any) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    document.querySelector('body')?.scrollTo(0, 100);
  }
}
