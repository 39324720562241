import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {
  private _config!: any;
  private _env!: any;

  public appName: string;
  public languages: any;
  public defaultLanguage: string;

  constructor(private http: HttpClient) {
    this.appName = 'pcs-webapp';
    this.languages = ['en'];
    this.defaultLanguage = 'en';
  }

  public loadAppEnv() {
    return new Promise((resolve, reject) => {
      this._config = environment.config;
      resolve(true);
    });
  }

  getEnv(key: any) {
    return this._env[key];
  }

  get(key: any) {
    return this._config[key];
  }
}
