import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { CustomPatternValidation } from 'src/app/shared/constants/validations';
import { RoleEnum } from 'src/app/core/enums/app-core.enum';
import { AlertService } from 'src/app/core/services/alert.service';
import { CmsService } from 'src/app/core/services/cms.service';
import { CryptoService } from 'src/app/core/services/crypto.service';
import { NewPassword, PcsUserManagementService } from 'src/app/core/services/pcs-user-management.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: [],
})
export class ChangePasswordComponent implements OnInit {
  currentPasswordVisibility = false;
  newPasswordVisibility = false;
  confirmPasswordVisibility = false;
  passLength = false;
  passwordMatch = false;
  changePassword!: FormGroup;
  @BlockUI()
  blockUI!: NgBlockUI;
  alertSubscribe!: Subscription;
  emailID: any;
  currentRole: any;
  changePasswordCMSData: any;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public userManagementService: PcsUserManagementService,
    private router: Router,
    private cmsService: CmsService,
    private crypto: CryptoService
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('token') || '';
    this.emailID = jwtDecode(token);
    this.currentRole = this.emailID.roleName;

    this.changePassword = this.formBuilder.group({
      currentPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    });
    this.cmsService.getCMSPreContent().subscribe((res) => {
      this.changePasswordCMSData = res;
    });
  }

  toggleFieldTextTypeCurrentPass(): void {
    this.currentPasswordVisibility = !this.currentPasswordVisibility;
  }

  toggleFieldTextTypeNewPass(): void {
    this.newPasswordVisibility = !this.newPasswordVisibility;
  }

  toggleFieldTextTypeConfirmPass(): void {
    this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
  }

  passwordLength(): void {
    const re = new RegExp(CustomPatternValidation.passwordFormat);
    re.test(this.changePassword.value.newPassword) ? (this.passLength = true) : (this.passLength = false);
    this.changePassword.value.newPassword === this.changePassword.value.confirmPassword
      ? (this.passwordMatch = true)
      : (this.passwordMatch = false);
  }

  keyFunc(): void {
    const re = new RegExp(CustomPatternValidation.passwordFormat);
    re.test(this.changePassword.value.confirmPassword) &&
    this.changePassword.value.newPassword === this.changePassword.value.confirmPassword
      ? (this.passwordMatch = true)
      : (this.passwordMatch = false);
  }

  enableButton(): boolean {
    return this.changePassword.valid && this.passLength && this.passwordMatch;
  }

  errorFunction(err: HttpErrorResponse): void {
    this.blockUI.stop();
    this.alertService.showErrorInformation(err);
  }

  logoutClickHandler() {
    if (this.currentRole === RoleEnum.RocheAdmin || this.currentRole === RoleEnum.SuperAdmin) {
      this.router.navigate(['/admin/login']);
    } else if (this.currentRole === 'Patient') {
      this.router.navigate(['/patient/login']);
    } else {
      this.router.navigate(['/']);
    }
  }

  changeNewPassword(): void {
    this.blockUI.start('Loading...');
    const newPassword: NewPassword = {
      newPassword: this.changePassword.value.newPassword,
      oldPassword: this.changePassword.value.currentPassword,
      userEmail: this.crypto.aesDecrypt(this.emailID.emailId),
    };
    this.userManagementService.changePassword(newPassword).subscribe(
      (res) => {
        this.blockUI.stop();
        if (res.error === false) {
          this.alertSubscribe = this.alertService
            .showTBTSuccess({
              title: 'Success!',
              text: `${res.data}`,
            })
            .subscribe((data) => {
              if (data) {
                this.changePassword.reset();
                this.passLength = false;
                this.passwordMatch = false;
                this.currentPasswordVisibility = false;
                this.newPasswordVisibility = false;
                this.confirmPasswordVisibility = false;
                this.logoutClickHandler();
              }
              this.alertSubscribe.unsubscribe();
            });
        }

        if (res.error === true) {
          this.alertSubscribe = this.alertService
            .showWarningError({
              title: 'Error',
              text: `${res.data.messages[0].toString()}`,
            })
            .subscribe((data) => {
              this.alertSubscribe.unsubscribe();
            });
        }
      },
      (err) => {
        this.errorFunction(err);
      }
    );
  }
}
