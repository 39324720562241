import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private booleanSubject = new BehaviorSubject<boolean>(false);
  boolean$ = this.booleanSubject.asObservable();

  private hideField = new BehaviorSubject<string>("");
  TestBoolean$ = this.hideField.asObservable();

  setBooleanVariable(value: boolean): void {
    this.booleanSubject.next(value);
  }
  setBooleanHideVar(value: string): void {
    this.hideField.next(value);
  }
}
