import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/features.module').then((features) => features.FeaturesModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((admin) => admin.AdminModule),
  },
  {
    path: '',
    loadChildren: () => import('./patient/patient.module').then((patient) => patient.PatientModule),
  },
  {
    path: 'portal',
    loadChildren: () => import('./tenant-form-portal/tenant-form-portal.module').then((tenant) => tenant.TenantFormPortalModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
