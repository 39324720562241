import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AppConfig } from '../configs/app.configs';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  key: string;
  ivKey: any;
  constructor(private _config: AppConfig) {
    this.key = _config.get('loginKey');
    this.ivKey = _config.get('ivKey');
  }

  ivConfig() {
    const iv = CryptoJS.enc.Utf8.parse(this.ivKey);
    const ivConfig = {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };
    return ivConfig;
  }

  /** This method is used for encryption and decryption using AES **/
  aesEncrypt(input: string) {
    const encryptKey = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(this.key));
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(input), encryptKey, this.ivConfig());
    return encrypted.toString();
  }

  aesDecrypt(encrypt: string | CryptoJS.lib.CipherParams) {
    const encryptKey = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(this.key));
    const decrypted = CryptoJS.AES.decrypt(encrypt, encryptKey, this.ivConfig());
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
