<div class="header-wrapper">
  <header>
    <nav class="navbar tbt-navbar navbar-expand-lg" *ngIf="currentRole !== roleEnum.MouriSuperAdmin"
      aria-label="Primary">
      <div class="container-fluid tbt-container" *ngIf="header_cmsContent?.hasOwnProperty('imagesandicons')">
        <a class="navbar-brand">
          <img src="{{
              header_cmsContent?.imagesandicons?.tbtheaderlogo
            }}" alt="PCS" width="46" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon">
            <img
              [src]="menu ? 'header_cmsContent.imagesandicons?.close_icon_dashboard' : 'header_cmsContent?.imagesandicons?.menu_icon'"
              alt="" class="img-fluid" (click)="menuIcon()" />
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item" *ngIf="showDashboardFlag && currentRole === roleEnum.Patient">
              <a class="nav-link" aria-current="page" routerLink="/patient-dashboard" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.Dashboard }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.dashboard }}
              </a>
            </li>
            <li class="nav-item" *ngIf="showDashboardFlag && currentRole === roleEnum.Patient">
              <a class="nav-link" aria-current="page" routerLink="/patient-foc-orders" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.Orders }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.trackOrder }}
              </a>
            </li>
            <li class="nav-item"
              *ngIf="showDashboardFlag && (currentRole === roleEnum.RocheAdmin || currentRole === roleEnum.SuperAdmin)">
              <a class="nav-link" aria-current="page"
                [ngClass]="currUrl?.includes('dashboard') ? 'nav-link active' : ''" routerLink="/admin/dashboard"
                (click)="openDashboard()">
                <img src="{{ header_cmsContent?.imagesandicons?.Dashboard }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.dashboard }}
              </a>
            </li>
            <li class="nav-item" *ngIf="
                showDashboardFlag &&
                (currentRole === roleEnum?.Supervisor ||
                  currentRole === roleEnum?.Team_Lead ||
                  currentRole === roleEnum?.Telle_Caller ||
                  currentRole === roleEnum?.Field_Counsellor )
              ">
              <a class="nav-link" aria-current="page"
                [ngClass]="currUrl?.includes('dashboard') ? 'nav-link active' : ''" routerLink="/dashboard"
                (click)="openDashboard()">
                <img src="{{
                    header_cmsContent?.imagesandicons?.Dashboard 
                  }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.dashboard }}</a>
            </li>
            <li class="nav-item" *ngIf="
                currentRole === roleEnum?.Supervisor ||
                currentRole === roleEnum?.Team_Lead ||
                currentRole === roleEnum?.Telle_Caller ||
                currentRole === roleEnum?.Field_Counsellor ||
                currentRole === roleEnum?.Distributor 
              ">
              <a class="nav-link" aria-current="page" routerLink="/patient-list" [ngClass]="
                  currUrl?.includes('patientSummary') ||
                  currUrl?.includes('patient-list') ||
                  currUrl?.includes('edit-lead') ||
                  currUrl?.includes('patient-details')
                    ? 'nav-link active'
                    : ''
                ">
                <img src="{{
                    header_cmsContent?.imagesandicons?.Patients
                  }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.patients }}</a>
            </li>
            <li class="nav-item" *ngIf="
              currentRole === roleEnum?.CareCoordinater ||
              currentRole === roleEnum?.CareCoordinaterManager
              ">
              <a class="nav-link" aria-current="page" routerLink="/admin/enrollment-list" routerLinkActive="active" >
                <img src="{{
                    header_cmsContent?.imagesandicons?.Patients
                  }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.patients }}</a>
            </li>
            <li class="nav-item"
              *ngIf="showPatientsFlag && (currentRole === roleEnum?.SuperAdmin || currentRole === roleEnum?.RocheAdmin)">
              <a class="nav-link" aria-current="page" routerLink="/patient-list" [ngClass]="
                  currUrl?.includes('admin-patient-summary') || currUrl?.includes('patient-list') ? 'nav-link active' : ''
                ">
                <img src="{{
                    header_cmsContent?.imagesandicons?.Patients 
                  }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.patients }}</a>
            </li>
            <!-- Start Taiwan demo header changes -->
            <!-- <li class="nav-item"
              *ngIf="currentRole === roleEnum?.CareCoordinaterManager || currentRole === roleEnum?.CareCoordinater">
              <a class="nav-link" aria-current="page">
                <img src="{{ header_cmsContent?.imagesandicons?.documents }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.documents }}</a>
            </li> -->
            <li class="nav-item"
              *ngIf="currentRole === roleEnum?.CareCoordinaterManager || currentRole === roleEnum?.CareCoordinater">
              <a class="nav-link" aria-current="page">
                <img src="{{ header_cmsContent?.imagesandicons?.adverseEvents }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.adverseEvents }}</a>
            </li>
            <li class="nav-item"
              *ngIf="currentRole === roleEnum?.CareCoordinaterManager || currentRole === roleEnum?.CareCoordinater">
              <a class="nav-link" aria-current="page">
                <img src="{{ header_cmsContent?.imagesandicons?.aeReporting }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.aeReporting}}</a>
            </li>
            <!-- <li class="nav-item"
              *ngIf="currentRole === roleEnum?.CareCoordinaterManager || currentRole === roleEnum?.CareCoordinater">
              <a class="nav-link" aria-current="page" routerLink="/admin/enroll-patient" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.epicx  }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.epicx }}</a>
            </li> -->
            <!-- end  -->
            <li class="nav-item" *ngIf="
                showDashboardFlag &&
                (currentRole === roleEnum?.Supervisor ||
                  currentRole === roleEnum?.Team_Lead ||
                  currentRole === roleEnum?.Telle_Caller ||
                  currentRole === roleEnum?.Field_Counsellor)
              ">
              <a class="nav-link" aria-current="page" routerLink="/order-details" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.Orders }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.orders }}
              </a>
            </li>
            <li class="nav-item" *ngIf="
                (currentRole === roleEnum?.RocheAdmin || currentRole === roleEnum?.SuperAdmin) && showExceptionsFlag && tenantId === 'india'
              ">
              <a class="nav-link" aria-current="page" routerLink="/admin/exception" [ngClass]="
                  currUrl?.includes('exception-dtails') || currUrl?.includes('exception') ? 'nav-link active' : ''
                ">
                <img src="{{ header_cmsContent?.imagesandicons?.Exceptions }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.exceptions }}</a>
            </li>

            <li class="nav-item dropdown" ngbDropdown *ngIf="currentRole === roleEnum?.SuperAdmin">
              <a class="nav-link dropdown-toggle logout-option" id="user-dropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle [ngClass]="
                  currUrl?.includes('roles-review-request') ||
                  currUrl?.includes('roles-permissions') ||
                  currUrl?.includes('doctor-linking-request') ||
                  currUrl?.includes('demo-users')
                    ? 'nav-link active'
                    : ''
                ">
                <span class="tbt-i tbt-i-user-permission-outline"></span>{{ header_cmsContent?.navbarlabels?.users
                }}</a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="user-dropdown" ngbDropdownMenu>
                <li class="nav-item">
                  <a class="nav-item" (click)="navigateToUsers('rolesAndPermissions')" ngbDropdownItem>{{
                    header_cmsContent?.navbarlabels?.rolesAndPermissions }}</a>
                <li class="nav-item">
                  <a class="nav-item" (click)="navigateToUsers('caRolesAndPermissions')" ngbDropdownItem> ca -{{
                    header_cmsContent?.navbarlabels?.rolesAndPermissions }}</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-item" (click)="navigateToUsers('demoUsers')" ngbDropdownItem>
                    {{ 'navabarLabels.demoUsers'  }}</a
                  >
                </li> -->
              </ul>
            </li>
            <li class="nav-item dropdown" ngbDropdown
              *ngIf="currentRole === roleEnum?.SuperAdmin && tenantId === 'india'">
              <a class="nav-link dropdown-toggle logout-option" id="user-dropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle [ngClass]="
                  currUrl?.includes('vas-service-list') ||
                  currUrl?.includes('add-service') ||
                  currUrl?.includes('add-service-provider') ||
                  currUrl?.includes('vas-provider-list') ||
                  currUrl?.includes('coupon-management')
                    ? 'nav-link active'
                    : ''
                ">
                <img src="{{ header_cmsContent?.imagesandicons?.VAS }}" alt="" class="me-1" />
                {{ header_cmsContent?.navbarlabels?.VAS }}</a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="user-dropdown" ngbDropdownMenu>
                <li class="nav-item">
                  <a class="nav-item" (click)="navigateToVasServiceProviderList('service')" ngbDropdownItem>
                    {{ header_cmsContent?.navbarlabels?.Services }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-item" (click)="navigateToVasServiceProviderList('provider')" ngbDropdownItem>
                    {{
                    header_cmsContent?.navbarlabels?.serviceProvider
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-item" (click)="navigateToVasServiceProviderList('coupon_management')" ngbDropdownItem>
                    {{ header_cmsContent?.navbarlabels?.couponManagementDropdown }}</a>
                </li>
              </ul>
            </li>


            <!-- <li class="nav-item" *ngIf="currentRole !== roleEnum?.Patient && currentRole === roleEnum.SuperAdmin">
              <a class="nav-link" aria-current="page" routerLink="/admin/program-categories-list"
                routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.categoriesAndOthers }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.categoriesAndOthers }}</a>
            </li> -->
            <li class="nav-item" *ngIf="currentRole !== roleEnum?.Patient && currentRole === roleEnum.SuperAdmin">
              <a class="nav-link" aria-current="page" routerLink="/admin/vendor-list" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.vendors }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.vendors }}</a>
            </li>

            <li class="nav-item"
              *ngIf=" tenantId !=='india' && currentRole !== roleEnum?.Patient && currentRole === roleEnum.SuperAdmin">
              <a class="nav-link" aria-current="page" routerLink="/admin/products-list" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.PAPDetails }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.productAndIndication }}</a>
            </li>
            <li class="nav-item"
              *ngIf="tenantId === 'india' && currentRole !== roleEnum?.Patient && currentRole === roleEnum.SuperAdmin">
              <a class="nav-link" aria-current="page" routerLink="/admin/pap-list" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.PAPDetails }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.PAP }}</a>
            </li>
            <li class="nav-item"
              *ngIf="tenantId == 'india' && currentRole !== roleEnum?.Patient && currentRole === roleEnum.SuperAdmin">
              <a class="nav-link" aria-current="page" routerLink="/admin/program-list" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.Orders }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.programs }}
              </a>
            </li>
            <li class="nav-item"
              *ngIf=" tenantId !=='india' && currentRole !== roleEnum?.Patient && currentRole === roleEnum.SuperAdmin">
              <a class="nav-link" aria-current="page" routerLink="/admin/schemes-list" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.Orders }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.scheme }}
              </a>
            </li>
            <li class="nav-item" *ngIf="currentRole === roleEnum?.Provider">
              <a class="nav-link" aria-current="page" routerLink="/service-list" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.RolesandPermissions }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.serviceList }}</a>
            </li>
            <li class="nav-item" *ngIf="currentRole === roleEnum?.Provider">
              <a class="nav-link" aria-current="page" routerLink="/service-requests" [ngClass]="
                  currUrl?.includes('service-requests') || currUrl?.includes('patientSummary') ? 'nav-link active' : ''
                ">
                <img src="{{ header_cmsContent?.imagesandicons?.ServiceDetails }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.serviceRequest }}</a>
            </li>
            <li class="nav-item" *ngIf="currentRole === roleEnum?.Provider">
              <a class="nav-link" aria-current="page" routerLink="/coupons" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.coupons }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.coupons }}
              </a>
            </li>
            <li class="nav-item" *ngIf="currentRole !== roleEnum?.Patient">
              <a class="nav-link" aria-current="page" routerLink="/reports" routerLinkActive="active">
                <img src="{{header_cmsContent?.imagesandicons?.Reports}}" alt="" />
                {{ header_cmsContent?.navbarlabels?.reports }}</a>
            </li>
            <li class="nav-item" *ngIf="currentRole === roleEnum?.Provider || currentRole === roleEnum?.Distributor">
              <a class="nav-link" aria-current="page" routerLink="/all/notification-alerts" routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.notifyIcon }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.notifications }}</a>
            </li>
            <li class="nav-item" *ngIf="currentRole !== roleEnum?.Provider && currentRole !== roleEnum?.Patient">
              <a class="nav-link" routerLink="/help" routerLinkActive="active">
                <img src="{{
                    header_cmsContent?.imagesandicons?.Help 
                  }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.help }}</a>
            </li>
            <li class="nav-item d-lg-none">
              <a class="nav-link" (click)="changePasswordClickHandler()" ngbDropdownItem>
                {{ header_cmsContent?.navabarlabels?.ChangePassowrd }}</a>
            </li>
            <li class="nav-item d-lg-none">
              <a class="nav-link" (click)="logoutClickHandler()" ngbDropdownItem>
                {{ header_cmsContent?.navabarlabels?.logout }}</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto d-none d-lg-flex">
            <li class="nav-item mx-2">
              <form class="translate-language" [formGroup]="languageForm">
                <select autocomplete="true" name="languageName" class="notranslate" aria-label="Default select"
                  formControlName="languageName" (change)="selectedLanguage($event)">
                  <option value="null" selected disabled hidden>Select language</option>
                  <option [value]="language.languageLocale" *ngFor="let language of languageList">
                    {{ language.languageName }}
                  </option>
                </select>
              </form>
            </li>
            <li class="nav-item mx-2" *ngIf="currentRole !== roleEnum.Patient && currentRole !== roleEnum.SuperAdmin">
              <form class="select-vendor" [formGroup]="vendorForm">
                <select autocomplete="true" name="vendorList" id="vendorList" aria-label="Default select"
                  formControlName="vendorName" (change)="selectedVendorSwitch($event)">
                  <option value="null" selected disabled hidden>Select Vendor</option>
                  <option [value]="vendor.vendorId" *ngFor="let vendor of vendorList">
                    {{ vendor?.vendorName }}
                  </option>
                </select>
              </form>
            </li>
            <li class="nav-item dropdown" ngbDropdown>
              <a class="nav-link dropdown-toggle logout-option" id="user-dropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                <div class="user-profile-img-wrapper me-1">
                  <img src="{{
                      header_cmsContent?.imagesandicons?.profile 
                    }}" class="img-fluid" width="30" alt="" />
                </div>
                <p class="user-details-wrapper text-truncate d-none d-xl-block"
                  *ngIf="this.currentRole !== roleEnum?.Patient">
                  <span class="user-name" *ngIf="accessToken">{{ currentUserFullName }}</span>
                  <br />
                  <span class="user-email" *ngIf="accessToken">{{ currentRole }}</span>
                </p>
                <p class="user-details-wrapper text-truncate d-none d-xl-block"
                  *ngIf="this.currentRole === roleEnum?.Patient">
                  <span class="user-name" *ngIf="accessToken">
                    {{ patientProfileInfo?.firstName }} {{ patientProfileInfo?.lastName }}</span>
                  <br />
                  <span class="user-email" *ngIf="accessToken"> {{ patientTbtId }}</span>
                </p>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="user-dropdown" ngbDropdownMenu>
                <li *ngIf="currentRole !== roleEnum.Patient">
                  <a class="dropdown-item" (click)="changePasswordClickHandler()" ngbDropdownItem>
                    {{ header_cmsContent?.navbarlabels?.ChangePassowrd }}
                  </a>
                </li>
                <li *ngIf="showDashboardFlag && currentRole === roleEnum.Patient">
                  <a class="dropdown-item" aria-current="page" routerLink="/patient-account">
                    {{ header_cmsContent?.navbarlabels?.myAccount }}
                  </a>
                </li>
                <li *ngIf="showDashboardFlag && currentRole === roleEnum.Patient">
                  <a class="dropdown-item" aria-current="page" (click)="openTermsPopup(terms)">
                    {{ header_cmsContent?.navbarlabels?.termsconditions }}
                  </a>
                </li>
                <li *ngIf="showDashboardFlag && currentRole === roleEnum.Patient">
                  <a class="dropdown-item" routerLink="/patient-help" ngbDropdownItem>{{
                    header_cmsContent?.navbarlabels?.help }}
                    <img src="{{ header_cmsContent?.imagesandicons?.help }}" alt="" /></a>
                </li>
                <li class="logout-responsive">
                  <a class="dropdown-item" (click)="logoutModal(logout)" ngbDropdownItem>
                    {{ header_cmsContent?.navbarlabels?.logout }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <nav class="navbar tbt-navbar navbar-expand-lg portal-header mouri-portal" *ngIf="currentRole === roleEnum.MouriSuperAdmin"
      aria-label="Primary">
      <div class="container-fluid tbt-container" *ngIf="header_cmsContent?.hasOwnProperty('imagesandicons')">
        <div class="brand-bg me-3">
          <a class="navbar-mt-brand">
            <img [src]="header_cmsContent?.imagesandicons?.mouri_logo" width="120" alt="" />
          </a>
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon">
            <img
              [src]="menu ? 'header_cmsContent?.imagesandicons?.close_icon_dashboard' : 'header_cmsContent?.imagesandicons?.menu_icon'"
              alt="" class="img-fluid" (click)="menuIcon()" />
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" routerLink="/portal/mt-forms" routerLinkActive="active"><img
                  [src]="header_cmsContent?.imagesandicons?.home" alt="" class="me-1" />{{ header_cmsContent?.tabsandpills?.home }}
                  </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" routerLink="/portal/list-of-forms" routerLinkActive="active"><img
                  [src]="header_cmsContent?.imagesandicons?.list_of_forms" alt=""  />{{ header_cmsContent?.navbarlabels?.list_of_forms }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" routerLink="/admin/program-categories-list"
                routerLinkActive="active">
                <img src="{{ header_cmsContent?.imagesandicons?.categoriesAndOthers }}" alt="" />
                {{ header_cmsContent?.navbarlabels?.categoriesAndOthers }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" routerLink="/portal/onboarding-list" routerLinkActive="active"><img
                  [src]="header_cmsContent?.imagesandicons?.tenant_boarding_icon" />{{ header_cmsContent?.navbarlabels?.tenant_boarding }}</a>
            </li>
            
          </ul>
          <ul class="navbar-nav ms-auto d-none d-lg-flex">
            <!-- <li class="nav-item mx-2">
              <form class="select-vendor" [formGroup]="vendorForm">
                <select
                  autocomplete="true"
                  aria-label="Default select"
                  formControlName="vendorName"
                  (change)="selectedVendorSwitch($event)"
                >
                  <option value="null" selected disabled hidden>Select Vendor</option>
                  <option [value]="vendor.vendorId" *ngFor="let vendor of vendorList">
                    {{ vendor?.vendorName }}
                  </option>
                </select>
              </form>
            </li> -->
            <li class="nav-item dropdown" ngbDropdown>
              <a class="nav-link dropdown-toggle logout-option" id="user-dropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                <div class="user-profile-img-wrapper me-1">
                  <img src="{{ header_cmsContent?.imagesandicons?.profile }}" class="img-fluid" width="30" alt="" />
                </div>
                <p class="user-details-wrapper text-truncate d-none d-xl-block">
                  <span class="user-name" *ngIf="accessToken">{{ currentRole }}</span>
                  <br />
                  <span class="user-email" *ngIf="accessToken">{{ currentMailId }}</span>
                </p>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="user-dropdown" ngbDropdownMenu>
                <li class="logout-responsive">
                  <a class="dropdown-item" (click)="portalLogout()" ngbDropdownItem>
                    {{ header_cmsContent?.navbarlabels?.logout }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</div>

<ng-template #logout let-c="close" let-d="dismiss">
  <div class="modal-wrapper review-submit-wrapper">
    <div class="review-wrapper-gif">
      <img src="{{ header_cmsContent?.imagesandicons?.logoutError }}" class="img-fluid" alt="" />
    </div>
    <div class="modal-header">
      <div class="modal-heading-wrapper">
        <h4 class="modal-title" id="modal-basic-title">
          {{
          header_cmsContent?.pageheadingsandparagraphs?.youLeaving

          }}
        </h4>
      </div>
    </div>
    <div class="modal-body">
      <div class="modal-body-wrapper">
        <p class="thank-you-message">
          {{ header_cmsContent?.pageheadingsandparagraphs?.wantToLogout }}
        </p>
      </div>

      <div class="actions-inner-wrapper mb-3">
        <button type="button" class="btn tbt-outline-primary-btn w-50" (click)="closeModel()">
          {{ header_cmsContent?.buttons?.cancel }}
        </button>
        <button type="button" class="btn tbt-primary-btn w-50 ms-3" (click)="logoutClickHandler()">
          {{ header_cmsContent?.labels?.yes }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Terms And Conditions Popup -->
<ng-template #terms let-c="close" let-d="dismiss">
  <div class="modal-wrapper tbt-patient-terms-popup">
    <div class="modal-header">
      <div class="modal-heading-wrapper">
        <h4 class="modal-title" id="modal-basic-title">
          {{ header_cmsContent?.labels?.terms }}
        </h4>
        <p>{{ header_cmsContent?.labels?.acceptOn }} {{ termsLastAcceptedDate | date: 'dd/MM/yyyy hh:mm:ss a' }}</p>
      </div>
    </div>
    <div class="modal-body">
      <div class="modal-body-wrapper mb-4 text-left">
        <div [innerHTML]="termsAndConditionsData || _publicTermsAndConditionsData"></div>
      </div>

      <div class="actions-inner-wrapper mb-3 justify-content-center">
        <button type="button" class="btn tbt-primary-btn px-5" (click)="closeModel()">
          {{ header_cmsContent?.buttons?.close }}
        </button>
      </div>
    </div>
  </div>
</ng-template>