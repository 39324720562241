import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { HttpWrapperService } from 'src/app/core/services/http-wrapper.service';
import { RoleEnum } from 'src/app/core/enums/app-core.enum';
import jwtDecode from 'jwt-decode';
import { CryptoService } from './crypto.service';
import { StorageService } from './storage.service';

export interface Signup {
  firstName: string;
  lastName: string;
  dob: string;
  userEmail: string;
  phoneNumber: string;
  employeeId: string;
  roleId: number;
}

export interface CreatePassword {
  password: any;
  userEmail: any;
}

export interface ResetPassword {
  emailId: any;
}

export interface NewPassword {
  newPassword: any;
  oldPassword: any;
  userEmail: any;
}

@Injectable({
  providedIn: 'root',
})
export class PcsUserManagementService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  currentRole: any;
  accessToken: any;
  userDetails: any;
  constructor(
    public httpWrapperService: HttpWrapperService,
    private router: Router,
    private crypto: CryptoService,
    private storageService: StorageService,
  ) {
    const user = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : null;
    this.currentUserSubject = new BehaviorSubject<any>(user);
    this.currentUser = this.currentUserSubject.asObservable();
    this.accessToken = this.storageService.getData('token');
    if(this.accessToken) {  
      this.userDetails = jwtDecode(this.accessToken);
      this.currentRole = this.crypto.aesDecrypt(this.userDetails?.roles);
      // this.currentRole = localStorage.getItem('role');
    }
  }
  /**
   * This is a login function
   * @param path - This is login url
   * @param body - This is a request body
   */
  // ***START SERVICES METHOD'S HERE ***
  // userManagementAPI
  public login(path: string, body: any): Observable<any> {
    const user = this.httpWrapperService.postRequest('userManagementAPI', path, body, false);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', user['token']);
    this.currentUserSubject.next(user);
    return user;
  }

  public getUserDetailsByEmpId(path: string): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', path, false);
  }

  signup(signup_data: Signup): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/signup', signup_data, false);
  }
  camunda_Signup(signup_data: Signup): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/camunda/signup', signup_data, false);
  }

  createPassword(Password_data: CreatePassword): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/pcs/api/create/pasword', Password_data, false);
  }

  forgetPassword(Password_data: ResetPassword): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/pcs/api/forgot/pasword', Password_data, false);
  }

  changePassword(Password_data: any): Observable<any> {
    return this.httpWrapperService.postRequest(
      'userManagementAPI',
      '/pcs/api/change/pasword',
     Password_data,
      false
    );
  }

  getAllVendorUserList(obj, vendorId: any) {
    return this.httpWrapperService.postRequest('userManagementAPI', `/users/by-vendor-and-role?vendorId=${vendorId}`, obj, false);
  }


  createVendorUser(VendorUser_data: any): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/users', VendorUser_data, false);
  }
  

  removeApprovers(approverId: string) {
    return this.httpWrapperService.deleteRequest('userManagementAPI', '/admin/approver/' + approverId, false);
  }

  addApprovers(object: { pcsId: any; approverEmployeeId: string | any[] }) {
    return this.httpWrapperService.postRequest('userManagementAPI', '/admin/addApprover', object, false);
  }

  getAdminApprovers(pcsId: string): Observable<any> {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/approvers/' + pcsId, false);
  }

  getDynamicTabForms(formCategory: any) {
    return this.httpWrapperService.getRequest('userManagementAPI', `/forms/formCategory/${formCategory}`, false);
  }

  saveDynamicTabForms(tabForm: any) {
    return this.httpWrapperService.postRequest('userManagementAPI', `/forms`, tabForm, false);
  }

  deleteDynamicTabForms(formId: any) {
    return this.httpWrapperService.postRequest('userManagementAPI', `/forms/${formId}`, formId, false);
  }

  getAdminDetails(empId: string): Observable<any> {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/' + empId, false);
  }

  getPermissionDetails(): Observable<any> {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/permissions', false);
  }

  getAdminUsers(): Observable<any> {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/exceptionApprovers', false);
  }
  getAllAgentDetailList(reviewDetails: string) {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/agentDetails/' + reviewDetails, false);
  }

  getAllRequestedRolesDetailsList(roleDetails: string) {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/roleDetails/' + roleDetails, false);
  }

  postDeactivateEmp(empId: string) {
    return this.httpWrapperService.postRequest('userManagementAPI', '/admin/deactivate/' + empId, {}, false);
  }

  addNewAdmin(adminObj: {
    firstName: any;
    lastName: any;
    emailId: any;
    contactNumber: any;
    dashboardPermission: any;
    exceptionPermission: any;
    patientsPermission: any;
  }): Observable<any> {
    return this.httpWrapperService.putRequest('userManagementAPI', '/admin/addAdmin', adminObj, false);
  }

  exceptionApprovalApi(status: string, comment: string | number | boolean, pcsId: string) {
    return this.httpWrapperService.putRequest(
      'userManagementAPI',
      '/admin/exceptionApproval?action=' + status + '&comments=' + encodeURIComponent(comment) + '&pcsId=' + pcsId,
      {},
      false
    );
  }

  postReactivateEmp(empId: string) {
    return this.httpWrapperService.postRequest('userManagementAPI', '/admin/reactivate/' + empId, {}, false);
  }

  updateReviewRequest(reviewApproveData: any) {
    return this.httpWrapperService.putRequest('userManagementAPI', '/admin/roleDetails', reviewApproveData, false);
  }

  camundaUpdateReviewRequest(reviewApproveData: any) {
    return this.httpWrapperService.putRequest('userManagementAPI', '/camunda/roleDetails', reviewApproveData, false);
  }

  getAdminStatusList() {
    return this.httpWrapperService.getRequest('userManagementAPI', '/admin/getAdminStatusList', false);
  }

  public logout() {
    if (this.currentRole === RoleEnum.RocheAdmin || this.currentRole === RoleEnum.SuperAdmin) {
      // this.router.navigate(['/admin/login']);
      localStorage.clear();
      this.router.navigate(['/admin/login']).then(() => {
        window.location.reload();
      });
    } else if (this.currentRole === 'Patient') {
      // this.router.navigate(['/patient/login']);
      localStorage.clear();
      this.router.navigate(['/patient/login']).then(() => {
        window.location.reload();
      });
    } else {
      // this.router.navigate(['/']);
      localStorage.clear();
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    this.currentUserSubject.next(null);
  }


  getAllCoverageTypes() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/common/coveragetype', false);
  }

  getAllDemoUsers() {
    return this.httpWrapperService.getRequest('enrollmentAPI', '/common/getDemoappUsers', false);
  }

  updateDemoUserAppType(demoUser): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/common/add-demoapp-status', demoUser, false);
  }

  searchDemoUserByPhNo(phoneNumber: string): Observable<any> {
    return this.httpWrapperService.getRequest('enrollmentAPI', `/common/search-demo-user/${phoneNumber}`, false);
  }

  addDemoUserApp(demoUser): Observable<any> {
    return this.httpWrapperService.postRequest('enrollmentAPI', '/common/add-demopatient', demoUser, false);
  }

  getListOfForms(data): Observable<any> {
    return this.httpWrapperService.getRequest(
      'userManagementAPI',
      `/forms?pageNo=${data?.pageNo}&pageSize=${data?.pageSize}&sortBy=${data?.sortBy}&sortDir=${data?.sortDir}`,
      false
    );
  }

  getTenantForm(formName): Observable<any> {
    return this.httpWrapperService.getRequest('userManagementAPI', '/forms/formName/' + formName, false);
  }

  postTenantForm(data: any): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/forms', data, false);
  }

  updateFormStatus(obj) {
    return this.httpWrapperService.postRequest('userManagementAPI', '/forms/formStatus', obj, false);
  }

  getAllDecisionsList(obj: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortBy: string;
  }): Observable<any> {  
    return this.httpWrapperService.postRequest('userManagementAPI', '/decision/getAllDecisions', obj, false);
  }

  getDecisions(){
    return this.httpWrapperService.getRequest('userManagementAPI', `/decision/decisionsList`, false);
  }

  addNewDecision(decisionObj: Object): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/decision', decisionObj, false);
  }
  updateDecision(decisionObj: Object, decisionId): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', `/decision/update/${decisionId}`, decisionObj, false);
  }


  getOneDecision(decisionId:number){
    return this.httpWrapperService.getRequest('userManagementAPI', `/decision/${decisionId}`, false);
  }

  deleteDelete(obj: Object,deleteId:number): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', `/category/delete/${deleteId}`, obj, false);
  }

  changeDecisionStatus(decisionsId, status) {
    return this.httpWrapperService.postRequest('userManagementAPI', `/decision/delete?typeOfDecisionId=${decisionsId}&isDelete=${status}`, '', false);
  }

  updateProgramApprovalFlow(AfId:any){
    return this.httpWrapperService.getRequest('userManagementAPI', `/programApprovalFlow/update/${AfId}`, false);
  }

  getAllProgramWorkFlowList(obj): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/programApprovalFlow/workFlowDataList', obj, false);
  }

  getOneProgramApprovalFlowRecord(AfId:any){
      return this.httpWrapperService.getRequest('userManagementAPI', `/programApprovalFlow/${AfId}`, false);
  }

  addProgramApprovalFlow(obj: any): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/programApprovalFlow', obj, false);
  }

  deleteProgramApprovalFlow(obj: any,AfId:number){
    return this.httpWrapperService.postRequest('userManagementAPI', `/programApprovalFlow/delete/${AfId}`, obj, false);
  }

  changeApprovalStatus(approvalId, status) {
    return this.httpWrapperService.postRequest('userManagementAPI', `/programApprovalFlow/delete?programTypeDecisionId=${approvalId}&isDelete=${status}`, '', false);
  }

  getAllProgramCategoriesList(obj: {
    pageNo: number;
    pageSize: number;
    searchValue: any;
    sortOrder: string;
    sortBy: string;
  }): Observable<any> {  
    return this.httpWrapperService.postRequest('userManagementAPI', '/category/getAllCategories', obj, false);
  }
  changeCategoryStatus(categoryId, status){
    return this.httpWrapperService.postRequest('userManagementAPI', `/category/delete?dynamicWorkflowCategoriesId=${categoryId}&isDelete=${status}`, '', false);
  }

  deleteCategory(obj: Object,categoryId:number): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', `/category/delete/${categoryId}`, obj, false);
  }

  addNewCategory(obj: Object): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', '/category', obj, false);
  }

  updateCategory(obj: any, categoryId): Observable<any> {
    return this.httpWrapperService.postRequest('userManagementAPI', `/category/update/${categoryId}`, obj, false);
  }

  getOneCategory(obj: any, categoryId):Observable<any>{
    return this.httpWrapperService.postRequest('userManagementAPI', `/category/${categoryId}`, obj, false);
  }



  addOnbordingTenant(obj):Observable<any> {  
    return this.httpWrapperService.postRequest('tenantManagementAPI', '/tee/new', obj, false);
  }
}
