import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable, Subject } from 'rxjs';

import { PcsUserManagementService } from './pcs-user-management.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject = new Subject<any>();
  sweetAlert: any = Swal;
  currentRole: any;
  userManagementService: PcsUserManagementService;

  swal: any = Swal.mixin({
    buttonsStyling: false,
    showConfirmButton: true,
    allowOutsideClick: false,
    showCloseButton: true,
    reverseButtons: true,
    customClass: {
      container: 'tbt-swal-container',
      confirmButton: 'btn tbt-primary-btn',
      cancelButton: 'btn tbt-outline-primary-btn',
    },
  });

  swalForm: any = Swal.mixin({
    buttonsStyling: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: true,
    allowOutsideClick: false,
    reverseButtons: true,
    customClass: {
      container: 'tbt-swal-container tbt-swal-form-container',
      confirmButton: 'btn tbt-outline-primary-btn"',
      cancelButton: 'btn tbt-outline-primary-btn',
    },
  });

  swalFormRed: any = Swal.mixin({
    buttonsStyling: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: true,
    allowOutsideClick: false,
    reverseButtons: true,
    customClass: {
      container: 'tbt-swal-container tbt-swal-form-container',
      confirmButton: 'btn tbt-btn-outline-danger',
      cancelButton: 'btn tbt-outline-primary-btn',
    },
  });

  swalFormBlue: any = Swal.mixin({
    buttonsStyling: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: true,
    allowOutsideClick: false,
    reverseButtons: true,
    customClass: {
      container: 'tbt-swal-container tbt-swal-form-container',
      confirmButton: 'btn tbt-outline-primary-btn',
      cancelButton: 'btn tbt-outline-primary-btn',
    },
  });

  swalFormOrange: any = Swal.mixin({
    buttonsStyling: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: true,
    allowOutsideClick: false,
    reverseButtons: true,
    customClass: {
      container: 'tbt-swal-container tbt-swal-form-container',
      confirmButton: 'btn tbt-btn-outline-warning',
      cancelButton: 'btn tbt-outline-primary-btn',
    },
  });

  swalFormGreen: any = Swal.mixin({
    buttonsStyling: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: true,
    allowOutsideClick: false,
    reverseButtons: true,
    customClass: {
      container: 'tbt-swal-container tbt-swal-form-container',
      confirmButton: 'btn tbt-outline-success-btn',
      cancelButton: 'btn tbt-outline-primary-btn',
    },
  });

  constructor(private router: Router, private inject: Injector) {
    this.currentRole = localStorage.getItem('role');
    this.userManagementService = this.inject.get(PcsUserManagementService);
  }

  public showErrorInformation(error: any) {
    const error401 = error.includes('401');
    const error404 = error.includes('404');
    const error403 = error.includes('403');
    const error503 = error.includes('503');
    const error500 = error.includes('500');
    if (error401) {
      error = {
        status: 401,
      };
    } else if (error404) {
      error = {
        status: 404,
      };
    } else if (error403) {
      error = {
        status: 403,
      };
    } else if (error503) {
      error = {
        status: 503,
      };
    } else if (error500) {
      error = {
        status: 500,
      };
    }
    switch (error.status) {
      case 401: {
        this.swal
          .fire({
            title: 'UNAUTHORIZED ACCESS',
            text: 'Session has been expired, please re-login again',
          })
          .then((result: { value: any }) => {
            if (result.value) {
              this.userManagementService.logout();
            }
          });
        break;
      }
      case 404: {
        this.showWarningError({
          title: 'NOT FOUND',
          text: error?.error?.message || 'Response not found',
        });
        break;
      }
      case 403: {
        this.swal
          .fire({
            title: 'ACCESS DENIED',
            text: 'Invalid Credentials, Please Login!',
          })
          .then((result: { value: any }) => {
            if (result.value) {
              this.userManagementService.logout();
            }
          });
        break;
      }
      case 500: {
        this.showWarningError({
          title: 'System Temporarily Unavailable',
          text: 'System undergoing maintenance or is otherwise temporarily unavailable!',
        });
        break;
      }
      case 503: {
        this.showWarningError({
          title: 'System Temporarily Unavailable',
          text: 'System undergoing maintenance or is otherwise temporarily unavailable!',
        });
        break;
      }
      default: {
        this.showWarningError({
          title: 'Request processing error',
          text: 'Request processing error, Please try later!',
        });
      }
    }
  }

  /** Method to show success sweet alert */
  showSuccessSweetAlert(titleMsg: string, successMsg: string): Observable<any> {
    this.sweetAlert
      .fire({
        title: titleMsg,
        text: successMsg,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Okay',
        showCloseButton: true,
        allowOutsideClick: false,
      })
      .then((result: { value: any }) => {
        if (result.value) {
          this.subject.next(true);
        }
      });

    return this.subject.asObservable();
  }

  /**
   * Replace the above `success` alert method with this.
   * Method to show Warning/Error */
  showTBTSuccess(messageConfig: SweetAlertOptions): Observable<any> {
    const options: SweetAlertOptions = {
      icon: 'success',
      iconColor: 'transparent',
      iconHtml: '<span class="tbt-swal2-success"></span>',
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Okay',
      showCloseButton: true,
      allowOutsideClick: false,
    };

    this.swal.fire({ ...options, ...messageConfig }).then((result: any) => {
      if (result.value) {
        this.subject.next(true);
      }
    });

    return this.subject.asObservable();
  }

  showTBTPatientSuccess(messageConfig: SweetAlertOptions): Observable<any> {
    const options: SweetAlertOptions = {
      icon: 'success',
      iconColor: 'transparent',
      iconHtml: '<span class="tbt-swal2-success"></span>',
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Okay',
      showCloseButton: true,
      allowOutsideClick: false,
      customClass: {
        container: 'tbt-patient-success-popup',
      },
    };

    this.swal.fire({ ...options, ...messageConfig }).then((result: any) => {
      if (result.value) {
        this.subject.next(true);
      }
    });

    return this.subject.asObservable();
  }

  showTBTPatientSuccessWithCustomIcon(messageConfig: SweetAlertOptions, iconClass: string): Observable<any> {
    const options: SweetAlertOptions = {
      icon: 'success',
      iconColor: 'transparent',
      iconHtml: '<span class="tbt-swal2-success"></span>',
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Okay',
      showCloseButton: true,
      allowOutsideClick: false,
      customClass: {
        container: `tbt-patient-success-popup ${iconClass}`,
      },
    };

    this.swal.fire({ ...options, ...messageConfig });

    return this.subject.asObservable();
  }

  /**
   * Replace the above warning/error alert method with this.
   * Method to show Warning/Error */
  showWarningError(messageConfig: SweetAlertOptions): Observable<any> {
    const options: SweetAlertOptions = {
      icon: 'error',
      confirmButtonText: 'Okay',
      showCloseButton: true,
      allowOutsideClick: false,
    };

    this.swal.fire({ ...options, ...messageConfig }).then((result: any) => {
      if (result.value) {
        this.subject.next(true);
      }
    });

    return this.subject.asObservable();
  }

  showTBTPatientWarningError(messageConfig: SweetAlertOptions): Observable<any> {
    const options: SweetAlertOptions = {
      icon: 'error',
      confirmButtonText: 'Okay',
      showCloseButton: true,
      allowOutsideClick: false,
      customClass: {
        container: 'tbt-patient-error-popup',
      },
    };

    this.swal.fire({ ...options, ...messageConfig }).then((result: any) => {
      if (result.value) {
        this.subject.next(true);
      }
    });

    return this.subject.asObservable();
  }

  showTBTPatientSweetAlertForConfirmation(): Observable<any> {
    this.sweetAlert
      .fire({
        title: 'Do you sure want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
        cancelButtonText: 'No',
        customClass: {
          container: 'tbt-patient-error-popup tbt-patient-confirmation-popup',
          cancelButton: 'tbt-outline-primary-btn',
          confirmButton: 'tbt-primary-btn',
        },
      })
      .then((result: { value: any; dismiss: Swal.DismissReason }) => {
        if (result.value) {
          this.subject.next(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.subject.next(false);
        }
      });
    return this.subject.asObservable();
  }

  showSuccessMsg(messageConfig: SweetAlertOptions): Observable<any> {
    const options: SweetAlertOptions = {
      icon: 'success',
      // iconColor: 'transparent',
      showConfirmButton: false,
      showCancelButton: false,
      // confirmButtonText: false,
      showCloseButton: false,
      allowOutsideClick: false,
      timer: 3000,
    };

    this.swal.fire({ ...options, ...messageConfig }).then((result: any) => {
      if (result.value) {
        this.subject.next(true);
      }
    });

    return this.subject.asObservable();
  }

  /* ----- Start of Navigating with params----- */
  redirect(routeLink: string) {
    this.router.navigate([routeLink]);
  }

  redirectWithOneParams(routeLink: any, id: any) {
    this.router.navigate([routeLink, id]); // navigate and send data from one component to second component
  }

  redirectWithTwoParams(routeLink: any, id: any, name: any) {
    this.router.navigate([routeLink, id, name]); // navigate and send data from one component to second component
  }
}
