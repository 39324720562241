import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent  {
  constructor(private router: Router) {}



  showHeaderFooter() {
    let flag = true;
    const excludeRoutePath: any = [
      '/login',
      '/landing',
      '/welcome',
      '/patient/login',
      '/patient/signin',
      '/portal/login',
      '/admin/login',
      '/patient-profile-creation',
      '/patient-terms',
      '/patient-splash',
      '/sign-up',
      '/forgot-password',
      '/create-password',
      '/change-password',
      '/terms-conditions',
      '/privacy-policy',
      '/maintenance',
      '/feedback',
      '/support',
      '/home',
      '/help-info',
      '/404',
      '/crowd-funding',
      '/financial-counselling',
      '/500'
      
    ];

    for (const path of excludeRoutePath) {
      if (this.router.url.includes(path) || this.router.url === '/') {
        flag = false;
        break;
      }
    }
    return flag;
  }
}
