import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';

import { PatientService } from 'src/app/features/services/patient.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { FeedBackCategoryArray } from 'src/app/core/enums/app-core.enum';
import { PcsFeedbackService } from 'src/app/features/services/pcs-feedback.service';
import { CmsService } from 'src/app/core/services/cms.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styles: [],
})
export class FeedbackComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI;
  feedbackForm!: FormGroup;
  pcsId: any;
  categoryId: any;
  id: any;
  enrollmentFlag = false;
  focDeliveryFlag = false;
  vasFlag = false;
  questionsList: any;
  alertSubscribe!: Subscription;
  feedbackCmsData: any;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private feedbackService: PcsFeedbackService,
    private cmsService : CmsService,
  ) {}

  ngOnInit(): void {
    this.cmsService.getCMSPreContent().subscribe((res) => {
      this.feedbackCmsData = res;
    });
    this.route.paramMap.subscribe((params) => {
      this.pcsId = params.get('pcsId');
      this.categoryId = params.get('categoryId');
      this.id = params.get('id'); // Patient serviceId or orderId
    });

    this.feedbackForm = this.formBuilder.group({
      review: [null],
    });

    this.identifySelectedCategory();
    this.getQuestionsBasedOnCategory();
  }

  identifySelectedCategory(): void {
    if (this.categoryId === FeedBackCategoryArray[0].feedbackCategoryId) {
      this.enrollmentFlag = true;
    } else if (this.categoryId === FeedBackCategoryArray[1].feedbackCategoryId) {
      this.focDeliveryFlag = true;
    } else if (this.categoryId === FeedBackCategoryArray[2].feedbackCategoryId) {
      this.vasFlag = true;
    }
  }

  setUpFeedbackFormGroup(questionsList: any): void {
    questionsList.forEach((item: any, index: any) => {
      this.feedbackForm.addControl(item?.questionarieId, new FormControl(null, [Validators.required]));
    });
  }

  // GET questions based on Category
  getQuestionsBasedOnCategory(): void {
    this.feedbackService.getFeedbackQuestionsBasedOnCategory(this.categoryId).subscribe(
      (e: any) => {
        this.questionsList = e?.data;

        if (e?.data) {
          this.setUpFeedbackFormGroup(e?.data);
        }
      },
      (err: any) => {
        this.errorFunction(err);
      }
    );
  }

  onSubmit(): void {
    let entries = Object.entries(this.feedbackForm.value);
    entries = entries.filter((ele) => ele[0] !== 'review');
    const requestObject = [] as any;

    entries.forEach((res) => {
      const obj = {
        feedbackQuestionarieId: res[0],
        feedbackRating: res[1],
      };

      requestObject.push(obj);
    });

    const popupTitle = 'Save changes?';
    const popupText = 'Your feedback will be saved';
    const popupConfirmButtonText = 'Save';
    const popupCancelButtonText = 'No, cancel';

    this.alertService.swal
      .fire({
        title: popupTitle,
        text: popupText,
        showCancelButton: true,
        confirmButtonText: popupConfirmButtonText,
        cancelButtonText: popupCancelButtonText,
        reverseButtons: false,
      })
      .then((result: any) => {
        if (result.value) {
          if (this.enrollmentFlag) {
            this.sendFeedbackForPatientEnrollment(requestObject);
          } else if (this.focDeliveryFlag) {
            this.sendFeedbackForPatientFoc(requestObject);
          } else if (this.vasFlag) {
            this.sendFeedbackForPatientService(requestObject);
          }
        }
      });
  }

  sendFeedbackForPatientService(requestObject: any): void {
    this.blockUI.start('Loading...');
    const skip = false;
    this.feedbackService
      .postFeedbackQuestionsForPatientService(this.feedbackForm.value.review, this.id, skip, this.pcsId, requestObject)
      .subscribe(
        (data: any) => {
          this.blockUI.stop();
          const titleMessage = 'Success!';
          if (data.error === false) {
            this.alertSubscribe = this.alertService
              .showTBTSuccess({
                title: titleMessage,
                text: `${data.data.toString()}`,
              })
              .subscribe((_data: any) => {
                this.alertSubscribe.unsubscribe();
                this.feedbackForm.reset();
              });
          }
          if (data.error === true) {
            this.alertSubscribe = this.alertService
              .showWarningError({
                title: 'Error',
                text: `${data.data.messages.toString()}`,
              })
              .subscribe((_data: any) => {
                this.alertSubscribe.unsubscribe();
              });
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.alertService.showErrorInformation(error);
        }
      );
  }

  sendFeedbackForPatientEnrollment(requestObject: any): void {
    this.blockUI.start('Loading...');
    const skip = false;
    this.feedbackService
      .postFeedbackQuestionsForPatientEnrollment(this.feedbackForm.value.review, skip, this.pcsId, requestObject)
      .subscribe(
        (data: any) => {
          this.blockUI.stop();
          const titleMessage = 'Success!';
          if (data.error === false) {
            this.alertSubscribe = this.alertService
              .showTBTSuccess({
                title: titleMessage,
                text: `${data.data.toString()}`,
              })
              .subscribe((_data: any) => {
                this.alertSubscribe.unsubscribe();
                this.feedbackForm.reset();
              });
          }
          if (data.error === true) {
            this.alertSubscribe = this.alertService
              .showWarningError({
                title: 'Error',
                text: `${data.data.messages.toString()}`,
              })
              .subscribe((_data: any) => {
                this.alertSubscribe.unsubscribe();
              });
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.alertService.showErrorInformation(error);
        }
      );
  }

  sendFeedbackForPatientFoc(requestObject: any): void {
    this.blockUI.start('Loading...');
    const skip = false;
    this.feedbackService
      .postFeedbackQuestionsForPatientFoc(this.feedbackForm.value.review, this.id, skip, this.pcsId, requestObject)
      .subscribe(
        (data: any) => {
          this.blockUI.stop();
          const titleMessage = 'Success!';
          if (data.error === false) {
            this.alertSubscribe = this.alertService
              .showTBTSuccess({
                title: titleMessage,
                text: `${data.data.toString()}`,
              })
              .subscribe((_data: any) => {
                this.alertSubscribe.unsubscribe();
                this.feedbackForm.reset();
              });
          }
          if (data.error === true) {
            this.alertSubscribe = this.alertService
              .showWarningError({
                title: 'Error',
                text: `${data.data.messages.toString()}`,
              })
              .subscribe((_data: any) => {
                this.alertSubscribe.unsubscribe();
              });
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.alertService.showErrorInformation(error);
        }
      );
  }

  errorFunction(err: HttpErrorResponse): void {
    this.blockUI.stop();
    this.alertService.showErrorInformation(err);
  }
}
